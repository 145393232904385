import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AppContext from "../context/AppContext";
import { useCookies } from 'react-cookie';


export default function Home() {
  const { id } = useParams();

  const [usuario, setUsuario] = useCookies(['usuario']);
  const { api, setColor, bg} = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [cookies, setCookie] = useCookies(['cliente']);
  let nombre = cookies?.cliente?.nombre || "";




  useEffect(() => {
    window.scrollTo(0, 0);
    if (!id) {
      console.warn("ID no definido, evitando la llamada de red");
      return;
    }

    const fetchUsuario = async () => {
      try {
        const response = await fetch(`${api}/api/usuarios/name/${id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name: id }) // Datos a enviar en el cuerpo de la solicitud
        });
        const data = await response.json();
        setUsuario('usuario', data, { path: '/', maxAge: 604800 });
        setIsLoading(true)

      } catch (error) {
        console.log('Error al obtener el usuario:', error);
      }
    };
   
    fetchUsuario();
  }, [id]);


  const getDynamicStyles = () => {
    // Lógica para determinar los estilos según el identificador
    if (usuario.usuario && usuario.usuario.img) {
    
      return {
        background: `url(${usuario.usuario.img}) center`,
        color: usuario.usuario?.color ,
      };
      
    }
  }

  const dynamicStyles = getDynamicStyles();

  return (
    id && isLoading ?

      <section id="hero" className="d-flex align-items-center " style={dynamicStyles}>
        <div className="container position-relative text-center text-lg-start"  data-aos="zoom-in" data-aos-delay="100">
          <div className="row">
            <div className="col-lg-8">

              <h1 style={{color: usuario.usuario?.colorsecond}}>Bienvenido {nombre ? nombre.toUpperCase() : ''} a <span style={{color: usuario.usuario?.color}}>{usuario.usuario.local} </span></h1>   <br />
              <h2 style={{color: usuario.usuario?.colorsecond}}> ELEGÍ, PEDÍ y PAGÁ desde tu celular.</h2>
              <h2 style={{color: usuario.usuario?.colorsecond}}>¡Mejor servicio, más tiempo para disfrutar!</h2>
              {/* <h2>Todo con solo un par de clicks</h2> */}

              <div className="btns">
                <Link to={`/${usuario?.usuario?.local}/menu/Local`} className="btn-menu animated fadeInUp scrollto" 
                style={{borderColor: usuario.usuario?.colorsecond, color:  usuario.usuario?.color  }}>Nuestro Salón</Link>
                {((usuario.usuario.take || usuario.usuario.delivery) && (usuario?.usuario?.plan?.id != 3)) &&
                 (<Link to={`/${usuario.usuario.local}/ubicacion`} className="btn-book animated fadeInUp scrollto" 
                 style={{borderColor: usuario.usuario?.colorsecond,  color:  usuario.usuario?.color }}>{usuario.usuario.take && ('Take Away -')} {usuario.usuario.delivery && ('Delivery')}</Link>)}
              </div>
            </div>
            <div className="col-lg-4 d-flex align-items-center justify-content-center position-relative" data-aos="zoom-in" data-aos-delay="200">
              <Link to={`/${usuario.usuario.local}/menu`} className="glightbox play-btn" style={{color: usuario.usuario?.colorsecond, background: usuario.usuario?.colorsecond}}></Link>
            </div>

          </div>
        </div>
      </section>

      : <div style={{background: bg}}  id="preloader"></div>

  )

}