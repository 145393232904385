import React, { useContext, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { initMercadoPago, Payment, Wallet } from '@mercadopago/sdk-react';
import AppContext from "../context/AppContext";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Skeleton } from "antd";


export default function PedidosMenu() {
  const [pedidos, setPedidos] = useState([]);
  const [ubicacion, setUbicacion] = useState([]);
  const [mesero, setMesero] = useCookies(["mesero"]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usuario] = useCookies(['usuario']);
  const userId = usuario.usuario?.id;
  const { active, api, setClick, total } = useContext(AppContext);
  const [porcentaje, setPorcentaje] = useState(0);
  const serweb = (porcentaje === 0) ? 'Bonificado' : '$' + (total / (porcentaje * 100)).toFixed(1);
  const sumTotal = (porcentaje === 0) ? total : (Number(serweb.replace('$', '')) + total).toFixed(1);
  const currentUrl = window.location.href;
  const rejected = currentUrl.includes('rejected');
  const [isLoading, setIsLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const [ingresos, setIngresos] = useState([]);
  const [totalLocal, setTotalLocal] = useState(0);
  const [totalTake, setTotalTake] = useState(0);
  const [totalDeli, setTotalDeli] = useState(0);
  const [PedidosAceptados, setPedidosAceptados] = useState(0);
  const [PedidosPendientes, setPedidosPendientes] = useState(0);
  const [totalMonto, setTotalMonto] = useState(0);
  const [cantidadPedidos, setCantidadPedidos] = useState(0);
  const [selectedOptionPago, setSelectedOptionPago] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState("connecting"); // "connecting", "connected", "disconnected"

  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const isLocalUrl =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1";
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const handleOptionSelect = (id, tiempo, estado, idusuario) => {
    setSelectedOption({
      id: id,
      tiempoespera: tiempo,
      estado: estado,
      idusuario: idusuario,
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleClick = (id) => {
    // Establece el estado de carga solo para el botón específico
    setIsLoading((prev) => ({
      ...prev,
      [id]: true, // El botón con este id estará en estado de carga
    }));
  };

  useEffect(() => {
    const fetchPago = async () => {
      handleClick(selectedOptionPago.id); // Activar el loading antes de la llamada a la API

      try {
        const response = await fetch(`${api}/api/pedidos/pago`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(selectedOptionPago),
        });

        if (response.ok) {
          const data = await response.json();
          // Manejar los datos de la respuesta si es necesario
        } else {
          // Manejar errores de la respuesta si es necesario
          console.error("Error en la respuesta:", response.statusText);
        }
      } catch (error) {
        console.error("Error en la llamada a la API:", error);
      } finally {
        setIsLoading((prev) => ({
          ...prev,
          [selectedOptionPago.id]: false, // El botón con este id deja de estar cargando
        }));
      }
    };

    selectedOptionPago && fetchPago();
  }, [selectedOptionPago]);
  useEffect(() => {
    const fetchEstado = async () => {
      handleClick(selectedOption.id); // Activar el loading antes de la llamada a la API

      try {
        const response = await fetch(`${api}/api/pedidos/estado`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(selectedOption),
        });

        if (response.ok) {
          // Manejar la respuesta de la API si es necesario
        } else {
          // Manejar errores de la respuesta si es necesario
          console.error("Error en la respuesta:", response.statusText);
        }
      } catch (error) {
        console.error("Error en la llamada a la API:", error);
      } finally {
        setIsLoading((prev) => ({
          ...prev,
          [selectedOption.id]: false, // El botón con este id deja de estar cargando
        }));
      }
    };

    selectedOption && fetchEstado();
  }, [selectedOption]);

  useEffect(() => {
    let jsonpedidos = [];
    let pedi = [];

    const userId = usuario.usuario.id;
    //const idmozo = mesero.mesero.id;

    let apiUrl = "";
    let endpoint = "";

    if (isLocalUrl) {
      apiUrl = "localhost:8093";
      endpoint = `ws:${apiUrl}/myHandler?userid=${userId}`;
    } else {
      apiUrl = "app.clickmenu.com.ar:8093";
      endpoint = `wss:${apiUrl}/myHandler?userid=${userId}`;
    }
    let reconnectTimeout;
    let socket;

    const connect = () => {
      socket = new WebSocket(endpoint);
      socket.onopen = function () {
        setConnectionStatus("connected");
        console.log("WebSocket connection established");
      };
      socket.addEventListener("open", () => {
        setConnectionStatus("connected");
      });
      try {
        socket.addEventListener("message", (event) => {
          const pedido = JSON.parse(event.data);
          if (pedido.pedidos) {
            jsonpedidos = pedido.pedidos;
            setPedidos(jsonpedidos);
            setIngresos(pedido.ingresos);

            const local = pedido.ingresos
              .filter(ingreso => ingreso.modalidad == "Local")
              .reduce((acc, ingreso) => {
                acc.totalMonto += ingreso.monto;
                acc.cantidadPedidos += 1;
                return acc;
              }, { totalMonto: 0, cantidadPedidos: 0 });

            setTotalLocal(local);

            const take = pedido.ingresos
              .filter(ingreso => ingreso.modalidad == "TAKE AWAY")
              .reduce((acc, ingreso) => {
                acc.totalMonto += ingreso.monto;
                acc.cantidadPedidos += 1;
                return acc;
              }, { totalMonto: 0, cantidadPedidos: 0 });

            setTotalTake(take);

            const deli = pedido.ingresos
              .filter(ingreso => ingreso.modalidad == "Delivery")
              .reduce((acc, ingreso) => {
                acc.totalMonto += ingreso.monto;
                acc.cantidadPedidos += 1;
                return acc;
              }, { totalMonto: 0, cantidadPedidos: 0 });

            setTotalDeli(deli);

            const aceptados = pedido.pedidos
              .filter(pedido => pedido.estado === "ACEPTADO")
              .length;
            setPedidosAceptados(aceptados);

            const pendientes = pedido.pedidos
              .filter(pedido => pedido.estado === "PENDIENTE")
              .length;
            setPedidosPendientes(pendientes);

            const { total, count } = pedido.pedidos.reduce((acc, pedido) => {
              acc.total += pedido.consumototal;
              acc.count += 1;
              return acc;
            }, { total: 0, count: 0 });

            const localValue = local.totalMonto;
            const takeValue = take.totalMonto;
            const deliValue = deli.totalMonto;

            // Suma los valores
            const montototal = localValue + takeValue + deliValue;

            // Establece el valor total
            setTotalMonto(montototal);
            setCantidadPedidos(count);

            pedido.ubicaciones && setUbicacion(pedido.ubicaciones);

            pedi = pedido.pedidos;
            setPedidos(Object.values(pedi));
            // const audio = new Audio(soundFile);

            // Reproduce el sonido cuando el componente se monta
            /*audio.play().catch((error) => {
              console.error("Audio playback failed:", error);
            });return () => audio.pause();*/

            setLoading(false); // Actualiza el estado de carga


          } else {
            jsonpedidos = pedido;
            setPedidos(jsonpedidos);

            setLoading(false);
          }
        });
      } catch (error) {
        console.error("Error al analizar el JSON:", error);
      }

      socket.onclose = (event) => {
        console.warn("WebSocket connection closed. Reconnecting...", event);
        setConnectionStatus("disconnected");
        scheduleReconnect();
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        socket.close(); // Ensures `onclose` is triggered
      };

      setSocket(socket);
    };
    const scheduleReconnect = () => {

      const timeout = Math.min(1000 * 2 ** reconnectAttempts, 30000); // Exponential backoff up to 30 seconds
      reconnectTimeout = setTimeout(() => {
        setConnectionStatus("connecting")
        setReconnectAttempts((prev) => prev + 1);
        connect();
      }, timeout);
    };

    connect();

    return () => {
      if (socket) socket.close();
      clearTimeout(reconnectTimeout);
    };

  }, [selectedOption]);

  const elementosFiltrados = pedidos.sort((a, b) => {
    if (a.Mesa === 1 && b.Mesa !== 1) {
      return -1;
    } else if (a.Mesa !== 1 && b.Mesa === 1) {
      return 1;
    } else {
      return 0;
    }
  });

  const connectionStatusIcons = {
    connected: "🛎️",
    connecting: "🟡",
    disconnected: "🔴",
    default: "🟡", // Icono por defecto
  };

  const getButtonIcon = (status) => connectionStatusIcons[status] || connectionStatusIcons.default;
  const getPaymentIcon = (pago) => {
    switch (pago) {
      case "TARJ":
      case "TARJETA":
        return 'credit_card';
      case "EFEC":
      case "EFECTIVO":
        return 'currency_exchange';
      case "TRAN":
      case "TRANSFERENCIA":
        return 'account_balance';

      case "MEPG":
      case "MERCADO PAGO":
        return 'phonelink_ring';
      // Agrega más casos según sea necesario
      default:
        return 'payment'; // Icono por defecto
    }
  };

  return (
    <section id="contact" className="sectionMenu section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-title mt-2">
          <h2>Pedidos Activos</h2>
          <p className="flex center">PEDIDOS<button
            style={{
              fontSize: "14px",
              border: "none",
              padding: "2px 4px",
              borderRadius: "5px",
              cursor: "pointer",
              fontWeight: "bold",
              backgroundColor: "transparent",
            }}
            title={`Estado actual: ${connectionStatus}`}
          >
            {getButtonIcon(connectionStatus)}
          </button></p>

        </div>
      </div>


      <div className="container" data-aos="fade-up">

        <br></br>
        <div className="accordion" id="accordionExample">

          {loading ? (


            <>
              <style>
                {`
                  .custom-skeleton .ant-skeleton-content .ant-skeleton-paragraph > li,
                  .custom-skeleton .ant-skeleton-content .ant-skeleton-title {
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, #cda45e 37%, rgba(0, 0, 0, 0.06) 63%);
                    background-size: 400% 100%;
                    animation: skeleton-loading 1.4s ease infinite;
                  }

                  @keyframes skeleton-loading {
                    0% {
                      background-position: 100% 50%;
                    }
                    100% {
                      background-position: 0 50%;
                    }
                  }
                `}
              </style>
              <Skeleton active className="custom-skeleton" />
            </>
          ) : elementosFiltrados.map((pedido, id) => {
            const collapseId = `collapse-${pedido.id}`;
            const headerId = `heading-${pedido.id}`;

            return (
              <div className="accordion-item mb-3" key={id}>
                <h2 className="accordion-header" id={headerId}>
                  <button
                    className="accordion-button grid " // Añadir "collapsed" para que el botón se vea en estado colapsado
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${collapseId}`}
                    aria-expanded="true" // Cambiar a false para que inicie colapsado
                    aria-controls={collapseId}
                  >
                    <div>
                      <strong>
                        {`Pedido #${pedido.numeropedido} - `}
                        {pedido?.mesa !== 0 ? `MESA ${pedido.mesa} | ` : `${pedido.modo} | `}

                      </strong>
                      {"" + pedido.nombrecliente}
                    </div>
                    <div>

                      <h6 className="price ms-auto center flex ">
                        {pedido.estado === "PAGADO" || pedido.estado === "USUARIOPAGO" ? "PAGADO" : "PAGO PENDIENTE"}
                      </h6>
                      <strong className="priceTotal flex align-items-center center gap-1 ">
                        <span class="material-icons-round">
                          {getPaymentIcon(pedido.pago)}

                        </span>
                        $
                        {pedidos
                          .find((pedidoo) => pedidoo.id === pedido.id)
                          .consumototal.toLocaleString("es-AR")}{" "}
                      </strong>

                    </div>

                  </button>
                </h2>
                <div
                  id={collapseId}
                  className="accordion-collapse collapse show" // Quitar la clase "show" para que inicie colapsado
                  aria-labelledby={headerId}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    {pedido.productos.map((product) => (
                      <ul className="factu" key={product.id}>
                        <li>{product.cantidad}x</li>
                        <li>{product.nombre}</li>
                        <li>${product.precio * product.cantidad}</li>
                        <li>
                          <h6>{product.observacion}</h6>
                        </li>
                      </ul>
                    ))}
                    {pedido.comentario && (
                      <div className="center cursiva text-warning border border-warning rounded-4 mb-3">
                        Observación: <h6>{pedido.comentario}</h6>
                      </div>
                    )}

                    {isLoading[pedido.id] ? (
                      <div className="center flex gap-2 justify-content-center">
                        <button className="btn btn-primaryMenu " key={pedido.id}>
                          <div className="loading d-block isLoading"></div>
                        </button>
                      </div>
                    ) : (
                      <div style={{ position: "relative" }} className=" flex center gap-2 justify-content-center">
                        {(pedido.estado !== "ACEPTADO" && pedido.estado !== "COMPLETADO" && pedido.estado !== "USUARIOPAGO") ? (
                          pedido.modo === "Local" ? (
                            <div className="flex center gap-2 justify-content-center">
                              <button type="button" className="btn btn-primaryMenu">
                                <span
                                  className=""
                                  onClick={() => {
                                    setSelectedOption({
                                      id: pedido.id,
                                      estado: "ACEPTADO",
                                      tiempoespera: "0",
                                      idusuario: userId,
                                    });
                                  }}
                                >
                                  Comandar
                                </span>
                              </button>{" "}
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                onClick={() =>
                                  setSelectedOption({
                                    id: pedido.id,
                                    tiempoespera: "00",
                                    estado: "CANCELADO",
                                    idusuario: userId,
                                  })
                                }
                              >
                                Cancelar
                              </button>
                            </div>
                          ) : (
                            <button type="button" className="btn btn-primaryMenu center">
                              <div className="dropdown dropup">
                                <span
                                  className="nav-link dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Confirmar
                                </span>
                                <ul
                                  className="dropdown-menu"
                                  data-popper-placement="top-start"
                                >
                                  <li>
                                    {" "}
                                    <span
                                      className="dropdown-item"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        setSelectedOption({
                                          id: pedido.id,
                                          tiempoespera: "15",
                                          estado: "ACEPTADO",
                                          idusuario: userId,
                                        });
                                      }}
                                    >
                                      15min
                                    </span>
                                  </li>
                                  <li>
                                    {" "}
                                    <button
                                      className="dropdown-item"
                                      onClick={() =>
                                        setSelectedOption({
                                          id: pedido.id,
                                          tiempoespera: "30",
                                          estado: "ACEPTADO",
                                          idusuario: userId,
                                        })
                                      }
                                    >
                                      30min
                                    </button>
                                  </li>
                                  <li>
                                    {" "}
                                    <button
                                      className="dropdown-item"
                                      onClick={() =>
                                        setSelectedOption({
                                          id: pedido.id,
                                          tiempoespera: "45",
                                          estado: "ACEPTADO",
                                          idusuario: userId,
                                        })
                                      }
                                    >
                                      45min
                                    </button>
                                  </li>
                                  <li>
                                    <hr className="dropdown-divider"></hr>
                                  </li>
                                  <li>
                                    {" "}
                                    <button
                                      className="dropdown-item btn-secondary"
                                      onClick={() =>
                                        setSelectedOption({
                                          id: pedido.id,
                                          tiempoespera: "00",
                                          estado: "CANCELADO",
                                          idusuario: userId,
                                        })
                                      }
                                    >
                                      Cancelar
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </button>
                          )
                        ) : pedido.estado == "USUARIOPAGO" ? (
                          <div className="">
                            <button
                              type="button"
                              className="btn btn-primary yellow"
                            >
                              <div className="dropdown dropend">
                                <span
                                  className="nav-link "
                                  onClick={() =>
                                    setSelectedOptionPago({
                                      id: pedido.id,
                                      idusuario: userId,
                                    })
                                  }
                                >
                                  Cerrar
                                </span>
                                {/* <ul className="dropdown-menu">dropdown-toggledata-bs-toggle="dropdown" role="top" aria-expanded="false" 
                  <li> <button className="dropdown-item"  onClick={() => setSelectedOptionPago({ id: pedido.id, pago:'EFEC', idusuario: userId})}>Efectivo</ button></li>
                    <li> <button className="dropdown-item"  onClick={() => setSelectedOptionPago({ id: pedido.id, pago: 'CRED', idusuario: userId })}>Crédito</ button></li>
                    <li> <button className="dropdown-item"   onClick={() => setSelectedOptionPago({ id: pedido.id, pago: 'DEBI', idusuario: userId})}>Débito</ button></li>
        
                    <li><hr className="dropdown-divider"></hr></li>
                    <li> <button className="dropdown-item"  onClick={() => setSelectedOptionPago({ id: pedido.id, pago: 'MEPG', idusuario: userId})}>Mercado Pago</ button></li>
                  </ul> */}
                              </div>
                            </button>

                            <button
                              type="button"
                              className="btn btnSecondary ml-4"
                              data-bs-dismiss="modal"
                            >
                              Factura
                            </button>
                          </div>
                        ) : (
                          <div className="grid center">
                            {/*      <button
                              type="button"
                              className="btn  btn-primaryMenu"
                            >
                              <div className="dropdown dropup">
                                <span
                                  className="nav-link "

                                  data-bs-toggle="dropdown"

                                  aria-expanded="false"
                                >
                                  Confirmar Pago
                                </span>
                                <ul className="dropdown-menu center " style={{ transform: 'translate3d(40px, -205px, 0px)' }}>
                                  <li>
                                    {" "}
                                    <button
                                      className="dropdown-item text-danger"
                                      onClick={() =>
                                        setSelectedOption({
                                          id: pedido.id,
                                          tiempoespera: "00",
                                          estado: "CANCELADO",
                                          idusuario: userId,
                                        })
                                      }
                                    >
                                      Cancelado
                                    </button>
                                  </li>

                                  <li><hr className="dropdown-divider"></hr></li>
                                  <li> <button className="dropdown-item" onClick={() => setSelectedOptionPago({ id: pedido.id, pago: 'EFECTIVO', idusuario: userId })}>Efectivo</button></li>
                                  <li> <button className="dropdown-item" onClick={() => setSelectedOptionPago({ id: pedido.id, pago: 'CREDITO', idusuario: userId })}>Crédito</button></li>
                                  <li> <button className="dropdown-item" onClick={() => setSelectedOptionPago({ id: pedido.id, pago: 'DEBITO', idusuario: userId })}>Débito</ button></li>
                                  <li> <button className="dropdown-item" onClick={() => setSelectedOptionPago({ id: pedido.id, pago: 'MEPG', idusuario: userId })}>Mercado Pago</button></li>


                                </ul>
                              </div>
                            </button> */}
                            {(pedido.estado !== "COMPLETADO") ? <button
                              type="button"
                              className="btn btn-primaryMenu"
                              data-bs-dismiss="modal"
                              onClick={() =>
                                setSelectedOption({
                                  id: pedido.id,
                                  tiempoespera: "00",
                                  estado: "COMPLETADO",
                                  idusuario: userId,
                                })
                              }
                            >
                              Sale Pedido
                            </button> : <div className="center cursiva ">

                              <h6 className="center flex align-items-center m-2 gap-2">
                                <span class="material-icons-round">
                                  verified</span>
                                Pedido Entregado</h6>
                            </div>}
                            {/*   <button
                              type="button"
                              className="btn btnSecondary"
                              data-bs-dismiss="modal"
                              onClick={() =>
                                setSelectedOption({
                                  id: pedido.id,
                                  tiempoespera: "00",
                                  estado: "CANCELADO",
                                  idusuario: userId,
                                })
                              }
                            >
                              Cancelar Pedido
                            </button> */}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                </div>
              </div>
            );
          })}



        </div>
        <div id="cart-add" className="section-p1 center">


          {/*       <div id="subtotal" className="align-right">
            {rejected ? <div id="subtotal" className="align-right">
              <h4 className="center"><i className="bi bi-x-circle"></i>Pago Rechazado</h4>
            </div> : <></>}

            <h4 className="center">Tu Resumen</h4>
    
              <tr>
                <td>Subtotal</td>
                <td className="precios">${  typeof total === 'number' ? total.toFixed(2) : ''}</td>
              </tr>
              <tr>
                <td><h6>Servicio web </h6></td>

          
                <td className="precios"><h6>{serweb}</h6> </td>
              </tr>
              <tr>
                <td><strong>Total</strong></td>
                <td className="precios"><strong>${  typeof sumTotal === 'number' ? sumTotal.toFixed(2) : ''}</strong></td>
              </tr>
              <tr>

             
              </tr>
       

            <div className="grid martop4">
            <span className=""> <input type="text" placeholder="Ingresa tu Cupón" className='cupon' />
              <button className="buttonMenu ">Agregar</button></span>
            </div>
            <button className="normal second topbot" data-bs-toggle="modal" data-bs-target='#exampleModal'>Formas de Pagos</button>
           
          </div>
 */}
          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content modal-contentMenu">
                <div className="modal-header">
                  <span data-bs-dismiss="modal" className="btn-close material-icons-outlined" translate="no">close</span>

                </div>
                <div className="modal-body modal-bodyMenu">

                  <form >


                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Pago Efectivo
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            Al retirar el pedido podrás pagar con efectivo.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Pago Tarjeta Debito-Crédito
                          </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            Al retirar el pedido podrás pagar con tu tarjeta de debito o crédito.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Transferencias - Envio Dinero MP
                          </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            Sólo se acepta transferencia directa desde el mismo banco emisor al banco receptor.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>

              </div></div>

          </div>

          {/* <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-contentMenu">
              <div className="modal-header">
              <span data-bs-dismiss="modal" className="btn-close material-icons-outlined" translate="no">close</span>

            </div>
          
               
              </div></div>

          </div> */}


        </div>
      </div>
    </section>
  )
}