
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react';
import useInitialState from "../hooks/useInitialState";
import "../vendor/bootstrap/css/bootstrap.min.css";
import "../vendor/boxicons/css/boxicons.min.css";
import "../vendor/quill/quill.snow.css";
import "../vendor/quill/quill.bubble.css";
import "../vendor/bootstrap-icons/bootstrap-icons.css";
import "../vendor/simple-datatables/style.css";
import '../assets/css/AdminApp.css';
import Tables from "../page/pageAdmin/tables";
import ProductsTable from "../page/pageAdmin/productsTable"
import Nav from "../utils/nav";
import Aside from "../utils/aside";
import ProductsAlta from "../page/pageAdmin/altaproduct";
import ClienteAlta from "../page/pageAdmin/altacliente";
import ClienteEditar from "../page/pageAdmin/updatecliente";
import UsuariosTable from "../page/pageAdmin/UsuariosTable";
import MesaAlta from "../page/pageAdmin/altamesa";
import Grafic from "../page/pageAdmin/grafic";
import Pedidos from "../page/pageAdmin/pedido";
import ProductoEditar from "../page/pageAdmin/updateproducto";
import ErrorBoundary from '../components/errorBoudaly.js';
import ErrorFallback from "../components/ErrorFallback";
import Register from "../page/pageAdmin/register";
import Navbar from "../utils/navbar";
import Login from "../page/pageAdmin/login";
import Error from "../components/error";
import AppContext from "../context/AppContext";
import Img from "../page/pageAdmin/img";
import PromocionAlta from "../page/pageAdmin/altapromocion";
import MeseroAlta from "../page/pageAdmin/altamesero";
import MeseroTable from "../page/pageAdmin/meseroTable";
import PromocionTable from "../page/pageAdmin/promocionTable";
import PromocionEditar from "../page/pageAdmin/updatepromocion";
import User from "../page/pageAdmin/user";
import QR from "../page/pageAdmin/qr";
import EditarMozo from "../page/pageAdmin/updatemesero";
import CategoriaTable from "../page/pageAdmin/categoriaTable";
import CategoriaEditar from "../page/pageAdmin/updatecategoria";
import ProveedoresAlta from "../page/pageAdmin/altaproveedores";
import InsumoAlta from "../page/pageAdmin/altainsumo";
import { useCookies } from "react-cookie";
import CajaTable from "../page/pageAdmin/cajaTable.js";
import MenuAdmin from "../page/pageAdmin/menuadmi.js";
import Sucrip from "../page/pageAdmin/sucrip.js";


export default function AdminApp() {
  // const registroExitoso = sessionStorage.getItem('registroExitoso');
  const [usuario] = useCookies(['usuarioadmi']);
  const registroString = localStorage.getItem('registroExitoso');
  const location = useLocation();


  const navigate = useNavigate();
  useEffect(() => {
    if (!registroString || !usuario.usuarioadmi) {
      localStorage.removeItem('registroExitoso');
      if (location.pathname.includes("registro")) {
        navigate('Admin/Registro', { replace: true });
      }
      else if (location.pathname.includes("login")) {

      navigate('Admin/Ingreso');
      }
    }
    if (registroString) {
      const { value, expirationTime } = JSON.parse(registroString);
      const currentTime = new Date().getTime();

      if (currentTime > expirationTime) {
        localStorage.removeItem('registroExitoso'); // Eliminar el valor expirado
        navigate('Admin/login');
      };
    }



  }, [registroString , usuario]);

  const clickMenu = () => {
    localStorage.removeItem('Admin')
    navigate('/', { replace: true });
  };

  const handleError = (error, info) => {
    // Aquí puedes realizar acciones adicionales, como enviar el error a un servicio de registro.
    console.error(error, info);
  };

  const renderView = () => registroString && usuario.usuarioadmi ?
    <>

      <Navbar></Navbar>
      <Aside></Aside>


      <Routes>

        <Route index path="/Admin/mesas" element={<ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}><Tables className="active" /></ErrorBoundary>} />
        <Route exact path='/Admin/productos' element={<ErrorBoundary><ProductsTable className="active" /></ErrorBoundary>} />
        <Route exact path='/Admin/meseros' element={<ErrorBoundary><MeseroTable className="active" /></ErrorBoundary>} />
        <Route exact path='/Admin/productoalta' element={<ErrorBoundary><ProductsAlta /></ErrorBoundary>} />
        <Route exact path='/Admin/proveedoralta' element={<ErrorBoundary><ProveedoresAlta /></ErrorBoundary>} />
        <Route exact path='/Admin/insumoalta' element={<ErrorBoundary><InsumoAlta /></ErrorBoundary>} />
        <Route exact path='/Admin/clientealta' element={<ErrorBoundary><ClienteAlta /></ErrorBoundary>} />
        <Route exact path='/Admin/meseroalta' element={<ErrorBoundary><MeseroAlta /> </ErrorBoundary>} />
        <Route exact path='/Admin/mesaalta' element={<ErrorBoundary><MesaAlta /></ErrorBoundary>} />
        <Route exact path='/Admin/promocionalta' element={<ErrorBoundary><PromocionAlta /></ErrorBoundary>} />
        <Route exact path='/Admin/promociones' element={<ErrorBoundary><PromocionTable /></ErrorBoundary>} />
        <Route exact path='/Admin/categorias' element={<ErrorBoundary><CategoriaTable /></ErrorBoundary>} />
        <Route exact path='/Admin/editarcategoria/:id' element={<ErrorBoundary><CategoriaEditar /></ErrorBoundary>} />
        <Route exact path='/Admin/editarpromocion/:id' element={<ErrorBoundary><PromocionEditar /></ErrorBoundary>} />
        <Route exact path='/Admin/editarcliente/:id' element={<ErrorBoundary><ClienteEditar /></ErrorBoundary>} />
        <Route exact path='/Admin/editarmozo/:id' element={<ErrorBoundary><EditarMozo /></ErrorBoundary>} />
        <Route exact path='/Admin/editarproducto/:id' element={<ErrorBoundary><ProductoEditar /></ErrorBoundary>} />
        <Route exact path='/Admin/usuarios' element={<ErrorBoundary><UsuariosTable></UsuariosTable></ErrorBoundary>} />
        <Route exact path='/Admin/caja' element={<ErrorBoundary><CajaTable></CajaTable></ErrorBoundary>} />
        <Route exact path='/Admin/graficos' element={<ErrorBoundary><Grafic></Grafic></ErrorBoundary>} />
        <Route index path='/Admin' element={<ErrorBoundary><Pedidos></Pedidos></ErrorBoundary>} />
        <Route exact path='/Admin/img' element={<ErrorBoundary><Img></Img></ErrorBoundary>} />
        <Route exact path='/Admin/qr' element={<ErrorBoundary><QR></QR> </ErrorBoundary>} />
        <Route exact path='/Admin/error' element={<ErrorBoundary><Error></Error></ErrorBoundary>} />
        <Route exact path='/Admin/perfil' element={<ErrorBoundary> <User></User> </ErrorBoundary>} />
        <Route exact path='/Admin/Registro' element={<ErrorBoundary><Register></Register></ErrorBoundary>} />
      </Routes>


      <div
        className="modal fade overflow-auto	"
        id="menuModal"
        tabIndex="-1"
        aria-labelledby="menuModalLabel"
        aria-hidden="true"
      >
        <div className="center">
          <div className="modal-content modal-content w-30 h-40 m-5">
            <div className="modal-header sticky-top border-transparent rounded-3" style={{ background: "#cda45e" }} >
              <span
                data-bs-dismiss="modal"
                className="btn-close material-icons-outlined"
                translate="no"
              >
                close
              </span>
            </div>
            <div className="modal-body modal-body bg-white border-white">
              <MenuAdmin></MenuAdmin>
            </div>
          </div>
        </div>
      </div>

    </>

    : <Routes>
{/*       <Route path='/Admin/Registro' element={<ErrorBoundary><Sucrip></Sucrip></ErrorBoundary>} />
   <Route index path='/Admin/*' element={<ErrorBoundary><Login></Login></ErrorBoundary>} />
 */}        <Route index path='/' element={<ErrorBoundary><Login></Login></ErrorBoundary>} /> 
      <Route index path='/Admin/*' element={<ErrorBoundary><Login></Login></ErrorBoundary>} />
      <Route exact path='/Admin/error' element={<ErrorBoundary><Error></Error></ErrorBoundary>} />
      {/* <Route path='/Admin/Login' element={<ErrorBoundary><Login></Login></ErrorBoundary>} /> */}
    </Routes>;



  return (

    <div className='App admi' id="bodyAdmi">


      {renderView()}

      <footer className="footer">
        <div className="copyright">
          &copy; Copyright <strong><span onClick={() => clickMenu()}>ClickMenu</span></strong>. All Rights Reserved
        </div>
        <div className="credits">

          Designed by <a href="https://www.linkedin.com/in/jeampierregonzalez/" target="_blank"
          >Jeampierre Gonzalez</a>
        </div>
      </footer>
    </div>

  )
}