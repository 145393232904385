import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppContext from "../context/AppContext";
import useInitialState from "../hooks/useInitialState";
import ItemCount from "../components/itemCount";
import { useCookies } from "react-cookie";
import { notification } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";

export default function Productos(props) {
  const [comentario, setComentario] = useState("");
  const [checked, setChecked] = useState(false);
  const [img, setImg] = useState();
  const [opcional, setOpcional] = useState([]);
  const [usuario, setUsuario] = useCookies(["usuario"]);
  const { addToCart, setClick, estado, color, bg, scolor } = useContext(AppContext);
  const [qty, setQty] = useState(1)

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault(); // Evita que la página vuelva a la anterior
      const modal = document.getElementById(`exampleModal-${img}`);
      const modalClass = document.querySelector("body");
      const backdrop = document.querySelector(".modal-backdrop");

      if (modal) {
        modal.classList.remove("show");
        modal.classList.remove("hide");
        modal.style.display = "none";
        document.body.classList.remove("modal-open");

        document.body.style.removeProperty("overflow");
        document.body.style.removeProperty("padding-right");
      }

      // modal.classList.remove('modal');
      //modal.classList.remove('fade');
      if (backdrop) {
        backdrop.remove(); // eliminar el backdrop
      }
    };

    window.history.pushState(null, "", window.location.href); // Agrega una nueva entrada de historial para evitar volver atrás
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [img]);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  // Estado para manejar los radios (ingredientes con máximo <= 1)
  const [selectedRadio, setSelectedRadio] = useState(null);

  const extra = (ingrediente) => {
    const newData = {
      costo: ingrediente.costo,
      id: ingrediente.id,
      producto: props.id
    };
    setOpcional((prevData) => [...prevData, newData]);
    handleCheckboxSelect(ingrediente)

  }

  // Función para manejar la selección de checkboxes
  const handleCheckboxSelect = (ingrediente) => {
    const allRequiredSelected = isRequiredIngredientSelected();
    setChecked(allRequiredSelected)

    setSelectedCheckboxes(
      (prevSelected) =>
        prevSelected.includes(ingrediente.id)
          ? prevSelected.filter((id) => id !== ingrediente.id) // Deselecciona si ya está seleccionado
          : [...prevSelected, ingrediente.id] // Selecciona si no está seleccionado
    );
  };

  // Función para manejar la selección de radios
  const handleRadioSelect = (ingrediente) => {
    const allRequiredSelected = isRequiredIngredientSelected();
    setChecked(allRequiredSelected)
    setSelectedRadio(ingrediente.id); // Solo selecciona un radio
  };



  // Función para comprobar que los ingredientes requeridos están seleccionados
  const isRequiredIngredientSelected = () => {


    if (!Array.isArray(props.ingrediente)) {
      console.error("props.ingrediente no es un array");
      return false;
    }

    // Agrupar ingredientes por subtipo.id
    const groupedBySubtipo = props.ingrediente.reduce((acc, ingrediente) => {
      const subtipoId = ingrediente.subtipo?.id;
      if (!subtipoId) return acc; // Ignorar si no tiene subtipo.id
      if (!acc[subtipoId]) acc[subtipoId] = []; // Crear grupo si no existe
      acc[subtipoId].push(ingrediente);
      return acc;
    }, {});


    const allSubtiposAreValid = Object.keys(groupedBySubtipo).every((subtipoId) => {
      const ingredientes = groupedBySubtipo[subtipoId];

      let isValid = false;

      const requiredIngredients = ingredientes.filter(ingrediente => ingrediente.tipo === "REQUERIDO");

      // Si no hay ingredientes requeridos, consideramos que este subtipo es válido
      if (requiredIngredients.length === 0) {
        return true; // O se puede cambiar a false si quieres que no haya subtipos vacíos
      }


      const radios = requiredIngredients
        .filter((ingrediente) => ingrediente.maximo === 1)

      if (radios) {
        const radiosSelected = requiredIngredients
          .filter((ingrediente) => ingrediente.maximo <= 1)
          .some((ingrediente) => selectedRadio === ingrediente.id);

        if (radiosSelected) {
          isValid = true
          setChecked(true)
        }
      }
      const checkboxes = requiredIngredients
        .filter((ingrediente) => ingrediente.maximo > 1)

      if (checkboxes.length >= 1) {

        const checkboxesSelected = requiredIngredients
          .filter((ingrediente) => ingrediente.maximo > 1)
          .some((ingrediente) => selectedCheckboxes.includes(ingrediente.id));

        if (checkboxesSelected) {
          isValid = true
          setChecked(true)

        } else {
          isValid = false
          setChecked(false)
        }
      }


      return isValid

    });

    // Retorna true si todos los subtipos tienen ingredientes requeridos seleccionados correctamente
    return allSubtiposAreValid;
  };

  const requeredExtra = () => {
    debugger
    const allRequiredSelected = isRequiredIngredientSelected();

    if (!allRequiredSelected) {
      notification.open({
        message: 'Agregar Ingredientes Requeridos 👆🏽 ',
        description: ' Debes seleccionar entre los ingrediente requeridos.',
        icon: <InfoCircleOutlined style={{ color: '#fd7e14' }} />,
      });
      return false;
    } else {
      notification.open({
        message: 'Se agregó producto.',
        // description: 'Se agrego el producto. 😋',
        icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
        duration: 3,
      })
      const modalElement = document.getElementById(`exampleModal-${props.id}`);

      const backdrop = document.querySelector('.modal-backdrop');


      if (modalElement) {
        // Remueve la clase 'show' que hace visible el modal
        modalElement.classList.remove('show');

        // Remueve el atributo 'aria-hidden'
        modalElement.setAttribute('aria-hidden', 'true');

        // Oculta el modal
        modalElement.style.display = 'none';
      }

      if (backdrop) {
        // Elimina el backdrop si existe
        backdrop.remove();
      }

      document.body.classList.remove('modal-open');
      document.body.style.overflow = 'auto';

      const adicional = selectedCheckboxes.concat(selectedRadio);


      return (


        addToCart(props, qty, adicional, opcional)

      );
    }

  }


  const requered = () => {
    notification.open({
      message: 'Se agregó producto.',
      // description: 'Se agrego el producto. 😋',
      icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
      duration: 1,
    })
    const modalElement = document.getElementById(`exampleModal-${props.id}`);

    const backdrop = document.querySelector('.modal-backdrop');


    if (modalElement) {
      // Remueve la clase 'show' que hace visible el modal
      modalElement.classList.remove('show');

      // Remueve el atributo 'aria-hidden'
      modalElement.setAttribute('aria-hidden', 'true');

      // Oculta el modal
      modalElement.style.display = 'none';
    }

    if (backdrop) {
      // Elimina el backdrop si existe
      backdrop.remove();
    }

    document.body.classList.remove('modal-open');
    document.body.style.overflow = 'auto';
    return (
      addToCart(props, qty)

    );
  }

  return (
    <div className="col-lg-6 menu-item" data-categoria={props.categoria}>
      <img
        src={props.img}
        loading="lazy"
        className="menu-img"
        data-bs-toggle="modal"
        id="menumodal"
        onClick={() => setImg(props.id)}
        data-bs-target={`#exampleModal-${props.id}`}
        style={{ borderColor: color }}
      />
      <a className="categoriaMenu">{props.categoria}</a>

      <div className="menu-content">
        <span style={{color: color, background: bg}} className={color ? '' : 'colorprimary'}>{props.nombre}</span>
         <span style={{color: color, background: bg}}>${props.price}</span>
      </div>
      {/*   */}
      <div className="menu-ingredients"  style={{color: scolor}}>
        {props.ingredients}{" "}
        {props?.adicionales || usuario?.usuario?.plan?.id === 3 || estado === "Cerrado" ? (
          <i
            class="bi bi-zoom-in"
            id="add"
            style={{ color: color }}
            onClick={() => setImg(props.id)}
            data-bs-target={`#exampleModal-${props.id}`}
            data-bs-toggle="modal"
          >
            {" "}
          </i>
        ) : (
          <ItemCount
            img={props.img}
            categoria={props.categoria}
            nombre={props.nombre}
            price={props.price}
            stock={props.stock}
            initial={1}
            id={props.id}
          />
        )}
      </div>

      <div
        className="modal fade"
        id={`exampleModal-${props.id}`}
        tabIndex="-1"
        aria-labelledby={`exampleModalLabel-${props.id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog" >
          <div className="modal-content modal-contentMenu" style={{ background: color }}>
            <div className="modal-header" >
              <span
                data-bs-dismiss="modal"
                className="btn-close material-icons-outlined"
                translate="no"
              >
                close
              </span>
            </div>

            <div className="modal-body modal-bodyMenu" style={{ background: bg, borderTop: color }}>
              <div
                className="accordion accordion-flush"
                id={`accordionFlushExample-${props.id}`}
              >
                <div className="gslide-media gslide-image">
                  <img
                    src={props.img}
                    loading="lazy"
                    className="zoomable imgModal"
                    data-bs-toggle="modal"
                    data-bs-target={`#exampleModal-${props.id}`}
                  />
                </div>
                <div className="flex pl-1">
                 <h5 className={color ? '' : 'colorprimaryMenu'} style={{ color: color }}>
                  {props.nombre}</h5>{" "}
                  <h5 style={{ color: color }}>${props.price}</h5>
                </div>
                <h6 className={`${scolor ? 'menu pl-1' : 'menu pl-1 letter-gris'}`} style={{ color: scolor }}>
                Ingredientes: {props?.ingredients && props?.ingredients}
                </h6>
                {(usuario?.usuario?.plan?.id !== 3 && estado === "Abierto") && (
                  <>
                    {Object.entries(
                      (props.ingrediente || []).reduce((acc, ingrediente) => {
                        // Crear clave única para agrupar por subtipo, tipo, y máximo permitido
                        const key = `${ingrediente.subtipo?.nombre}-${ingrediente.tipo}-${ingrediente.maximo}`;

                        // Si no existe este grupo, crearlo
                        if (!acc[key]) {
                          acc[key] = {
                            subtipo: ingrediente.subtipo?.nombre,
                            tipo: ingrediente.tipo,
                            maximo: ingrediente.maximo,
                            ingredientes: [],
                          };
                        }

                        // Agregar el ingrediente al grupo correspondiente
                        acc[key].ingredientes.push(ingrediente);

                        return acc;
                      }, {})
                    ).map(([key, group], groupIndex) => (
                      <div key={groupIndex} className="mb-2">
                        <div className="row mt-1">
                          <div className="col-12">
                            <div className="d-flex align-items-center justify-content-around p-2 rounded">
                              <h6 className="text-left m-0 text-uppercase subtipo primaryMenu">
                                <strong>
                                  {group.tipo === "OPCIONAL"
                                    ? "Extras"
                                    : group.subtipo}
                                </strong>
                              </h6>
                              <div className="maximo letter-gris">
                                {group.tipo === "OPCIONAL"
                                  ? "Otros le sumaron:"
                                  : "Requerido. Elegir: " + group.maximo}
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul className="list-group bg-transparent">
                          {/* Mapear los ingredientes dentro del grupo */}
                          {group.tipo === "OPCIONAL" ? (
                            <ul className="list-group">
                              {group.ingredientes.map((ingrediente, index) => (
                                <li
                                  className={`list-group-item d-flex justify-content-between align-items-center bg-transparent text-secondary pb-1 ${selectedCheckboxes.includes(ingrediente.id)
                                    ? ""
                                    : ""
                                    }`}

                                  style={{ cursor: "pointer" }}
                                  key={ingrediente.id || index}
                                >
                                  <div className="form-check pl-5 flex">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`ingrediente${ingrediente.id}`}
                                      value={ingrediente.nombre}
                                      name={
                                        `ingrediente${ingrediente.id}`
                                      }
                                      checked={
                                        selectedCheckboxes.includes(ingrediente.id) // Verifica si el checkbox está seleccionado
                                      }
                                      onChange={() =>
                                        extra(ingrediente)
                                      }
                                    />
                                    <label
                                      className="form-check-label text-light"
                                      style={{ width: '200px', cursor: "pointer" }}
                                      htmlFor={`ingrediente${ingrediente.id}`}
                                    >
                                      {ingrediente.nombre}
                                    </label>
                                  </div>
                                  <div
                                    className="yellow badge-primary badge-pill ml-3 text-dark"
                                    style={{ cursor: "pointer" }} // Asegura que el cursor sea de tipo puntero
                                    onClick={() => extra(ingrediente)} // Maneja la selección al hacer clic
                                  >
                                    + ${ingrediente.costo.toFixed(0)}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            group.ingredientes.map((ingrediente, index) => (
                              <li
                                className={`list-group-item d-flex justify-content-between align-items-center bg-transparent text-secondary pb-1 ${selectedRadio === ingrediente.id ? "" : ""
                                  }`}

                                style={{ cursor: "pointer" }}
                                key={ingrediente.id || index}
                              >
                                <div className="form-check pl-5">
                                  {/* Evaluamos si ingrediente.maximo es mayor a 1 */}
                                  <input
                                    className="form-check-input"
                                    type={
                                      ingrediente.maximo > 1 ? "checkbox" : "radio"
                                    } // Condicional para cambiar entre radio y checkbox
                                    id={`ingrediente${ingrediente.id}`}
                                    value={ingrediente.id}
                                    name={
                                      ingrediente.maximo > 1
                                        ? `ingrediente_${ingrediente.id}`
                                        : "ingredientes"
                                    } // Asigna diferentes nombres
                                    required={ingrediente.maximo <= 1} // Requiere selección en radio
                                    checked={
                                      ingrediente.maximo > 1
                                        ? selectedCheckboxes.includes(
                                          ingrediente.id
                                        )
                                        : selectedRadio === ingrediente.id
                                    }
                                    onChange={() =>
                                      ingrediente.maximo > 1
                                        ? handleCheckboxSelect(ingrediente)
                                        : handleRadioSelect(ingrediente)
                                    }
                                  />
                                  <label
                                    className="form-check-label text-light"
                                    style={{ width: '200px', cursor: "pointer" }}
                                    htmlFor={`ingrediente${ingrediente.id}`}
                                    onClick={() =>
                                      ingrediente.maximo > 1
                                        ? handleCheckboxSelect(ingrediente)
                                        : handleRadioSelect(ingrediente)
                                    }
                                  >
                                    {ingrediente.nombre}
                                  </label>
                                </div>
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    ))}





                    <div className='qty flex  mt-2'>
                      <div className="flex pl-1">
                        <h5 className={color ? '' : 'colorprimaryMenu'} style={{ color: color }}>Cantidad</h5>{" "}
                      </div>
                      <div className="flex pl-1 center">
                        <span style={{ color: scolor }}
                         onClick={() => {
                          if (qty == 1) return;
                          setQty(qty - 1)
                          //  restToCart(props, -1);
                        }}
                          disabled={qty == 1 ? true : false}
                          className="material-symbols-outlined">
                          <i className="bi bi-dash-circle"></i>
                        </span>

                        <span id="cantidad" style={{ color: color }} 
                        className={`${scolor ? "pl-4 pr-4" :  'pl-4 pr-4 yellowLetter'}`} >{qty} </span>

                        <span className="material-symbols-outlined pr-4"
                        style={{ color: color }}
                          onClick={() => {
                            if (qty === props.stock) return;
                            // addToCart(props, 1);

                            setQty(qty + 1)
                          }} disabled={props.stock - qty === 0 ? true : false}>
                          <i className="bi bi-plus-circle"></i>
                        </span>
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <textarea
                        className="form-control"
                        name="message"
                        rows="1"
                        placeholder="Aclaraciones"
                        onChange={(e) => setComentario(e.target.value)}
                      ></textarea>
                    </div>
                    {props.adicionales ? <div className="center colorprimary">
                      <i   className={`${color ? "bi bi-plus-circle" :  'bi bi-plus-circle yellowLetter'}`}  style={{ color: color }}  onClick={() => { requeredExtra() }} id="add"></i>
                    </div> :
                      <div className="center colorprimary">
                        <i  className={`${color ? "bi bi-plus-circle" :  'bi bi-plus-circle yellowLetter'}`}  style={{ color: color }} onClick={() => { requered() }} id="add"></i>
                      </div>}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* {
                                        cart.card.map((props) => (

                                            <>


                                                <div >
                                                    <span onClick={() => {
                                                        if (props.qty == 1) return;
                                                        // setShowError(true);
                                                        restToCart(props, -1);
                                                    }}
                                                        // disabled={qty - props.qty <= 0 ? true :  false} 
                                                        className="material-symbols-outlined">
                                                        remove_circle_outline
                                                    </span>

                                                    <span id="cantidad" className='colorprimary'>{props.qty} </span>





                                                </div>

                                              

                                            </>))} */
}
