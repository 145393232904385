import React, { useContext, useState, useEffect, useRef } from "react";

import {
    useTable,
    usePagination,
    useSortBy,
    useGlobalFilter,
} from "react-table";
import "../../assets/css/sb-admin-2.css";
import "../../vendor/datatables/dataTables.bootstrap4.css";
import AppContext from "../../context/AppContext";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { message, Modal, notification, Skeleton } from "antd";
import axios from "axios";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

export default function CajaTable() {
    const [usuario] = useCookies(["usuarioadmi"]);
    const { active, api, setTitulo } = useContext(AppContext);
    const [data, setData] = useState([]);
    const [totalesPorPago, setTotalesPorPago] = useState({
        EFECTIVO: { total: 0, cantidad: 0 },
        TARJETA: { total: 0, cantidad: 0 },
        MEPG: { total: 0, cantidad: 0 },
    });
    const [cajaCentral, setCajaCentral] = useState(0);
    const [cajaChica, seCajaChica] = useState(0);
    const [tipo, setTipo] = useState([]);
    const [pago, setPago] = useState([]);
    const [monto, setMonto] = useState([]);
    const [tipoCaja, setTipoCaja] = useState([]);

    const [validate, setValidate] = useState(false);


    const [loading, setLoading] = useState(false);
    const [loadingenery, setLoadingenery] = useState(true);
    const fetchData = async () => {
        const result = await getData();

        setData(result);
    };

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        setOpen(false);
    };



    const handleSubmit = async (event) => {
        event.preventDefault();
        debugger
        if ((tipo.length == 0 || pago.length == 0 || monto.length == 0 || monto == 0)) {
            setValidate(false);
            notification.open({
                message: 'Error',
                description: 'Todos los campos son obligatorios. Por favor, complete todos los campos.',
                type: 'error',
            });
            return;
        }

        setValidate(true);
        const caja = {
            tipo: tipo,
            pago: pago,
            monto: monto,
            modalidad: 'Caja',
            idusuario: usuario.usuarioadmi.id,
            /*
            tipocaja: tipoCaja, cajaCentral: tipoCaja == "central" ? 1 : null,
            cajaChica: tipoCaja == "chica" ? 1 : null, */
        };

        const getRegistro = async () => {
            try {
                const response = await fetch(`${api}/api/caja/registro`, {
                    method: "POST",
                    body: JSON.stringify(caja),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (response.status === 200 || response.status === 201) {

                    setOpen(false);
                    setTipo([]);
                    setPago([]);
                    setMonto([]);


                    notification.open({
                        message: 'Notificación',
                        description: 'Registro exitoso',
                        icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
                    });

                }
            } catch (error) {

                notification.open({
                    message: "Ocurrio un error 😢",
                    description: `${error.response?.data || error.message}`,
                    icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
                });
            }
        };

        await getRegistro();
    };


    const getData = async () => {
        try {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ idusuario: usuario.usuarioadmi.id }),
            };

            const response = await fetch(`${api}/api/caja`, requestOptions);
            const result = await response.json();



            setLoadingenery(false);

            const totales = result.reduce((acc, transaccion) => {
                const { pago, monto, tipo } = transaccion;
            
                // Inicializar el acumulador para el tipo de pago si no existe
                if (!acc[pago]) {
                    acc[pago] = { total: 0, cantidad: 0 };
                }
            
                // Actualizar el total y la cantidad según el tipo de transacción
                if (tipo === 'ingreso') {
                    acc[pago].total += monto; // Sumar al total para ingresos
                } else if (tipo === 'egreso') {
                    acc[pago].total -= monto; // Restar al total para egresos
                }
            
                // Incrementar la cantidad de transacciones para cualquier tipo (ingreso o egreso)
                acc[pago].cantidad += 1;
            
                return acc;
            }, { EFECTIVO: { total: 0, cantidad: 0 }, DEBITO: { total: 0, cantidad: 0 }, CREDITO:{ total: 0, cantidad: 0 }, MEPG: { total: 0, cantidad: 0 }, TRANSFERENCIA: { total: 0, cantidad: 0 } });
            
            setTotalesPorPago(totales);


            return result;
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setTitulo('Caja')
        fetchData();
    }, [open]);

    const openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message: message,
            description: description,
        });
    };

    const handleToggle = (productId, isActive, nombre) => async () => {
        Modal.confirm({
            title: `¿Estás seguro de ${isActive ? "desactivar" : "activar"
                } este producto "${nombre}"?`,
            okText: isActive ? "Desactivar" : "Activar",
            cancelText: "Cancelar",
            okButtonProps: {
                style: {
                    backgroundColor: isActive ? "#ff4d4f" : "#52c41a",
                    borderColor: isActive ? "#ff4d4f" : "#52c41a",
                }, // Cambia el color de fondo y borde
            },
            onOk: async () => {
                setLoading(true);
                try {
                    // Notifica al backend
                    await axios.post(`${api}/api/caja`, {
                        idusuario: usuario.usuarioadmi.id,
                    });
                    openNotificationWithIcon(
                        "success",
                        "Operación Exitosa",
                        `Producto ${!isActive ? "activado" : "desactivado"} con éxito`
                    );

                    // Actualiza el estado del producto en la tabla localmente
                    setData((prevProducts) => {
                        const updatedProducts = prevProducts.map((product) =>
                            product.id === productId ? { ...product, activo: !isActive } : product
                        );

                        // Ordenar los productos por fecha de forma descendente
                        updatedProducts.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));

                        return updatedProducts;
                    });
                } catch (error) {
                    openNotificationWithIcon(
                        "error",
                        "Error",
                        "Ocurrió un error al intentar cambiar el producto"
                    );
                } finally {
                    setLoading(false);
                }
            },
            onCancel() {
                // Cancelar acción
            },
        });
    };

    const columns = React.useMemo(
        () => [
            /*  {
                 Header: "Id",
                 accessor: "id",
             }, */
            {
                Header: "Tipo",
                accessor: "tipo",
                Cell: ({ value }) => (
                    <span
                        style={{ color: value === "ingreso" ? "#05cd99" : "#dc3545", fontSize: "24px" }}
                        title={value === "ingreso" ? "Ingreso" : "Egreso"}
                    >
                        {value === "ingreso" ? <i class="bi bi-arrow-up-circle-fill"></i>
                            : <i class="bi bi-arrow-down-right-circle-fill"></i>}
                    </span>
                ),
            },
            {
                Header: "Fecha",
                accessor: "fecha",
            },
            {
                Header: "Ticket",
                accessor: "orden",
            },


            {
                Header: "Monto",
                accessor: "monto",
                Cell: ({ value }) => `$${value.toFixed(2)}`,
            },
            {
                Header: "Pago",
                accessor: "pago",
            },

            {
                Header: "Modalidad",
                accessor: "modalidad",
            },
            {
                Header: "N° Pedido",
                accessor: "idpedido",
            },

            /* {
                Header: "Acciones",
                // Cell: ({ row }) => (
                //     <i className="bi bi-trash3-fill" onClick={() => eliminarusuario(row.original.id)}></i>
                // ),
                Cell: ({ row }) => (
                    <Link to={"/Admin/editarproducto/" + row.original.id}>
                        <i className="bi bi-pencil-square"></i>
                    </Link>
                ),
            }, */
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setPageSize,
        setGlobalFilter,
        searchTerm,
        setSearchTerm,
        pageOptions,
    } = useTable(
        {
            // eslint-disable-next-line no-undef
            columns,
            data,
            initialState: {
                pageIndex: 0, pageSize: 10, sortBy: [
                    {
                        id: 'fecha',
                        desc: true, // Set to true for descending order
                    },
                ],
            },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { globalFilter, pageIndex, pageSize } = state;

    const [filterInput, setFilterInput] = useState("");

    const handleFilterChange = (e) => {
        const value = e.target.value || "";
        setGlobalFilter(value);
        setFilterInput(value);
    };

    const handlePageSizeChange = (e) => {
        setPageSize(Number(e.target.value));
    };

    React.useEffect(() => {
        setGlobalFilter(filterInput);
    }, [filterInput, setGlobalFilter]);

    const canvasRef = useRef(null);

    useEffect(() => {
        if (!loadingenery) {

            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");

            // Configurar el canvas
            canvas.width = 100; // Ancho del canvas
            canvas.height = 80; // Altura del canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Crear el trazo de la curva
            ctx.beginPath();
            ctx.lineWidth = 4;
            const gradient = ctx.createLinearGradient(
                0,
                0,
                canvas.width,
                canvas.height
            );
            gradient.addColorStop(0, "#f1f1f1");
            gradient.addColorStop(1, "#E6E4E100");
            ctx.strokeStyle = gradient;

            // Dibujar la curva
            ctx.moveTo(5, 80); // Punto inicial
            ctx.quadraticCurveTo(30, 20, 60, 40); // Punto de control y punto final
            ctx.quadraticCurveTo(80, 60, 100, 20); // Segunda curva

            // Renderizar
            ctx.stroke();
        }
    }, []);

    return (
        <main id="main" className={active === "active" ? "main active" : "main"}>

            <div className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 css-1r0g6ic">
                <div className="MuiStack-root css-1ym788i">
                    <div className="MuiStack-root css-14de4cz">
                        <h4 className="MuiTypography-root MuiTypography-h4 css-bxpo38">
                            <font style={{ verticalAlign: "inherit;" }}>
                                <font style={{ verticalAlign: "inherit;" }}>Balance</font>
                            </font>
                        </h4>
                        <div className="MuiStack-root css-8qcydu">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                role="img"
                                className="iconify iconify--ic MuiBox-root css-1nzz4nu"
                                width="1em"
                                height="1em"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2M9.29 16.29L5.7 12.7a.996.996 0 1 1 1.41-1.41L10 14.17l6.88-6.88a.996.996 0 1 1 1.41 1.41l-7.59 7.59a.996.996 0 0 1-1.41 0"
                                ></path>
                            </svg>
                            <p className="MuiTypography-root MuiTypography-body1 css-odkaru">
                                <font style={{ verticalAlign: "inherit;" }}>
                                    <font style={{ verticalAlign: "inherit;" }}>En camino</font>
                                </font>
                            </p>
                        </div>
                    </div>
                    {/* <div className="MuiFormControl-root css-1dthcdz">
                        <div className="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-colorPrimary MuiInputBase-formControl  css-lhom0n">
                            <div
                                tabindex="0"
                                role="combobox"
                                aria-controls=":r2:"
                                aria-expanded="false"
                                aria-haspopup="listbox"
                                aria-labelledby="select-filled"
                                id="select-filled"
                                className="MuiSelect-select MuiSelect-filled MuiInputBase-input MuiFilledInput-input css-incp5c"
                            >
                                <font style={{ verticalAlign: "inherit;" }}>
                                    <font style={{ verticalAlign: "inherit;" }}>Mensual</font>
                                </font>
                            </div>
                            <input
                                aria-invalid="false"
                                aria-hidden="true"
                                tabindex="-1"
                                className="MuiSelect-nativeInput css-1k3x8v3"
                                value="monthly"
                            />
                            <svg
                                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconFilled css-zyxmcg"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="ArrowDropDownIcon"
                            >
                                <path d="M7 10l5 5 5-5z"></path>
                            </svg>
                        </div>
                    </div> */}
                </div>
                <div
                    className="echarts-for-react MuiBox-root css-rai6sj"
                    _echarts_instance_="ec_1733158337607"
                    size-sensor-id="5"
                    style={{ userSelect: 'none', position: 'relative' }}>
                    <div className="endFlex mt-4 mb-4">
                        {/*   <Link to="/Admin/productoalta"
                    className=" btn btn-primary rounded-pill"
                    style={{ width: "100px", height: "37px" }}>
                    <i className="bi bi-person-add"></i> Alta</Link> */}



                        <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2.5 css-es6bie">
                            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-xl-3 css-1s7a4db">
                                <div className="MuiStack-root MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 css-e24385">
                                    {!loadingenery ? <div className="MuiStack-root css-278k3a">

                                        <div className="MuiBox-root css-0">
                                            <p className="MuiTypography-root MuiTypography-body2 css-19t5b1o">
                                                Total <strong>Efectivo</strong>
                                            </p>
                                            <h5 className="MuiTypography-root MuiTypography-h3 css-v8sqh">
                                                ${totalesPorPago.EFECTIVO?.total.toFixed(2)}
                                            </h5>
                                            <div className="MuiStack-root css-1nkpd5i">
                                                <div className="MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorSuccess MuiChip-filledSuccess css-1qni6z8">
                                                    <span className="MuiChip-label MuiChip-labelMedium css-1rsidz7">
                                                        <font style={{ verticalAlign: "inherit" }}>
                                                            <font style={{ verticalAlign: "inherit" }}>
                                                                {totalesPorPago.EFECTIVO?.cantidad} Ticket
                                                            </font>
                                                        </font>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>



                                    </div> : <Skeleton active />}
                                </div>
                            </div>

                            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-xl-3 css-1s7a4db">
                                <div className="MuiStack-root MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 css-e24385">
                                    {!loadingenery ? <div className="MuiBox-root css-0">
                                        <p className="MuiTypography-root MuiTypography-body2 css-19t5b1o">
                                            Total <strong>Transferencia</strong>
                                        </p>
                                        <h5 className="MuiTypography-root MuiTypography-h3 css-v8sqh">
                                            ${totalesPorPago.TRANSFERENCIA?.total.toFixed(2)}
                                        </h5>
                                        <div className="MuiStack-root css-1nkpd5i">
                                            <div className="MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorSuccess MuiChip-filledSuccess css-1qni6z8">
                                                <span className="MuiChip-label MuiChip-labelMedium css-1rsidz7">
                                                    <font style={{ verticalAlign: "inherit" }}>
                                                        <font style={{ verticalAlign: "inherit" }}>{totalesPorPago.TRANSFERENCIA?.cantidad} Ticket</font>
                                                    </font>
                                                </span>
                                            </div>
                                        </div>
                                    </div> : <Skeleton active />}
                                    {/*   <div className="MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorSuccess MuiChip-filledSuccess css-1qni6z8">
                                <span className="MuiChip-label MuiChip-labelMedium css-1rsidz7">
                                    <font style={{ verticalAlign: "inherit" }}><font style={{ verticalAlign: "inherit" }}>+2,45%</font></font></span>
                            </div> */}
                                </div>
                            </div>






                            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-xl-3 css-1s7a4db">
                                <div className="MuiStack-root MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 css-e24385">
                                    {!loadingenery ? <div className="MuiBox-root css-0  pt-0">
                                        <p className="MuiTypography-root MuiTypography-body2 css-19t5b1o">
                                            Total  <strong>Tarjetas -<br></br> Mercado Pago</strong>
                                        </p>
                                        <h5 className="MuiTypography-root MuiTypography-h3 css-v8sqh">
                                            ${( totalesPorPago.DEBITO?.total + totalesPorPago.CREDITO?.total + totalesPorPago?.MEPG?.total ).toFixed(2)}
                                        </h5>



                                        <div className="MuiStack-root css-1nkpd5i">
                                            <div className="MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorSuccess MuiChip-filledSuccess css-1qni6z8">
                                                <span className="MuiChip-label MuiChip-labelMedium css-1rsidz7">
                                                    <font style={{ verticalAlign: "inherit" }}>
                                                        <font style={{ verticalAlign: "inherit" }}>{totalesPorPago?.MEPG?.cantidad + totalesPorPago?.CREDITO?.cantidad + totalesPorPago?.DEBITO?.cantidad } Ticket</font>
                                                    </font>
                                                </span>
                                            </div>
                                        </div>
                                    </div> : <Skeleton active />}
                                    {/*   <div className="MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorSuccess MuiChip-filledSuccess css-1qni6z8">
                                <span className="MuiChip-label MuiChip-labelMedium css-1rsidz7">
                                    <font style={{ verticalAlign: "inherit" }}><font style={{ verticalAlign: "inherit" }}>+2,45%</font></font></span>
                            </div> */}
                                </div>
                            </div>
                            {/* Earnings */}
                            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-xl-3 css-1s7a4db">
                                <div className="MuiStack-root MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 css-e24385 pl-1">
                                    {!loadingenery ? <div className="MuiStack-root css-278k3a">
                                        <div className="MuiStack-root css-7zbfc4">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true"
                                                role="img"
                                                className="iconify iconify--ic MuiBox-root css-131zg17"
                                                width="1em"
                                                height="1em"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M6 20c1.1 0 2-.9 2-2v-7c0-1.1-.9-2-2-2s-2 .9-2 2v7c0 1.1.9 2 2 2m10-5v3c0 1.1.9 2 2 2s2-.9 2-2v-3c0-1.1-.9-2-2-2s-2 .9-2 2m-4 5c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2s-2 .9-2 2v12c0 1.1.9 2 2 2"
                                                ></path>
                                            </svg>
                                        </div>
                                        <div className="MuiBox-root css-0">
                                            <p className="MuiTypography-root MuiTypography-body2 css-19t5b1o">
                                                Saldo <strong>Caja Chica</strong>
                                            </p>
                                            <h5 className="MuiTypography-root MuiTypography-h3 css-v8sqh">
                                                ${totalesPorPago.EFECTIVO?.total.toFixed(2)}
                                            </h5>
                                            <div className="MuiStack-root css-1nkpd5i">
                                                <div className="MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorSuccess MuiChip-filledSuccess css-1qni6z8">
                                                    <span className="MuiChip-label MuiChip-labelMedium css-1rsidz7">
                                                        <font style={{ verticalAlign: "inherit" }}>
                                                            <font style={{ verticalAlign: "inherit" }}>
                                                                +2,45%
                                                            </font>
                                                        </font>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : <Skeleton active avatar paragraph={{ rows: 2 }} />}
                                </div>
                            </div>

                            {/* Activity */}

                            <div className="MuiStack-root MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 css-nkftsk css-1s7a4db">
                                {!loadingenery ?
                                    <div className="MuiBox-root css-0">
                                        <p className="MuiTypography-root MuiTypography-body2 css-1skutlr">
                                            Saldo <strong>Caja Central</strong>
                                        </p>
                                        <h3 className="MuiTypography-root MuiTypography-h3 css-gysky2">
                                            ${(totalesPorPago.DEBITO?.total + totalesPorPago.CREDITO?.total + totalesPorPago?.MEPG?.total).toFixed(2)}
                                        </h3>

                                        {/*        <div className="MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorSuccess MuiChip-filledSuccess css-1qni6z8 m-0 mt-1  bg-gray-200">
                                            <span className="MuiChip-label MuiChip-labelMedium css-1rsidz7">
                                                <font style={{ verticalAlign: "inherit" }}>
                                                    <font style={{ verticalAlign: "inherit" }}>+{totalesPorPago.MEPG.cantidad}</font>
                                                </font>
                                            </span>
                                        </div> */}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="img"
                                            className="iconify iconify--ic MuiBox-root css-131zg17 color-white"
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                            
                                        >
                                            <path
                                                fill="#e9ecef"
                                                d="M6 20c1.1 0 2-.9 2-2v-7c0-1.1-.9-2-2-2s-2 .9-2 2v7c0 1.1.9 2 2 2m10-5v3c0 1.1.9 2 2 2s2-.9 2-2v-3c0-1.1-.9-2-2-2s-2 .9-2 2m-4 5c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2s-2 .9-2 2v12c0 1.1.9 2 2 2"
                                            ></path>
                                        </svg>
                                    </div> : <Skeleton active avatar paragraph={{ rows: 2 }} />}

                                {!loadingenery && <div
                                    className="echarts-for-react MuiBox-root css-v4qsmk"
                                    data-echarts-instance="ec_1733158337605"
                                    style={{
                                        height: "300px",
                                        position: "relative",
                                    }}
                                >
                                    {/* Contenedor del canvas */}
                                    <div
                                        style={{
                                            position: "relative",
                                            width: "75px",
                                            height: "68px",
                                            padding: "0px",
                                            margin: "0px",
                                            borderWidth: "0px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <canvas
                                            ref={canvasRef}
                                            style={{
                                                width: "75px",
                                                height: "68px",
                                                display: "block",
                                            }}
                                        />
                                    </div>

                                    <div>

                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>

                    {/*        <div style="position: relative; width: 665px; height: 220px; padding: 0px; margin: 0px; border-width: 0px; cursor: pointer;">
              <canvas
                data-zr-dom-id="zr_0"
                width="1330"
                height="440"
                style="position: absolute; left: 0px; top: 0px; width: 665px; height: 220px; user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); padding: 0px; margin: 0px; border-width: 0px;"
              ></canvas>
            </div> */}
                    <div
                        className=""
                        style={{
                            position: 'absolute',
                            display: 'block',
                            borderStyle: 'solid',
                            whiteSpace: 'nowrap',
                            zIndex: 9999999,
                            boxShadow: 'rgba(0, 0, 0, 0.2) 1px 2px 10px',
                            transition: 'opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1), visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1), transform 0.4s cubic-bezier(0.23, 1, 0.32, 1)',
                            backgroundColor: 'rgb(255, 255, 255)',
                            borderWidth: '1px',
                            borderRadius: '4px',
                            color: 'rgb(102, 102, 102)',
                            font: '14px / 21px "Microsoft YaHei"',
                            padding: '10px',
                            top: '0px',
                            left: '0px',
                            transform: 'translate3d(275px, 127px, 0px)',
                            borderColor: 'rgb(255, 255, 255)',
                            pointerEvents: 'none',
                            visibility: 'hidden',
                            opacity: 0,
                        }}
                    >
                        <font style={{ verticalAlign: "inherit;" }}>
                            <font style={{ verticalAlign: "inherit;" }}>Enero: $40</font>
                        </font>
                    </div>
                </div>
            </div>

            <div className="card shadow mb-4">
                <div className="card-header py-3 flex">
                    <label className="pr-4 flex center align-items-baseline">
                        Mostrar{" "}
                        <select
                            disabled={data.length < pageSize}
                            value={pageSize}
                            onChange={handlePageSizeChange}
                            className="custom-select custom-select-sm form-control form-control-sm ml-2 mr-2"
                        >
                            {[5, 10, 20, 30, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>{" "}
                        {data.length > 0 && (
                            <span
                                className="ml-2"
                                style={{ whiteSpace: "nowrap", display: "inline-block" }}
                            >
                                ({data.length} Totales)
                            </span>
                        )}
                    </label>

                    <div className="col-sm-12 col-md-6 grid">
                        <div id="usuarios_filter" className="dataTables_filter">
                            <label>
                                Buscar:
                                <input
                                    type="search"
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-controls="usuarios"
                                    value={filterInput}
                                    onChange={handleFilterChange}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive"></div>
                    {loadingenery ? (
                        <div className="center">
                            {" "}
                            <div className=" spinner"></div>
                        </div>
                    ) : (
                        <>
                            {" "}
                            <table {...getTableProps()} className="table table-bordered">
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th
                                                    {...column.getHeaderProps(
                                                        column.getSortByToggleProps()
                                                    )}
                                                    className={
                                                        column.isSorted
                                                            ? column.isSortedDesc
                                                                ? "sort-desc"
                                                                : "sort-asc"
                                                            : ""
                                                    }
                                                >
                                                    {column.render("Header")}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => (
                                                    <td {...cell.getCellProps()}>
                                                        {cell.render("Cell")}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div className="col-sm-12 col-md-7">
                                <div
                                    className="dataTables_paginate paging_simple_numbers"
                                    id="dataTable_paginate"
                                >
                                    <ul className="pagination">
                                        <li
                                            className={
                                                pageIndex + 1 !== 1
                                                    ? "paginate_button page-item previous"
                                                    : "paginate_button page-item previous disabled"
                                            }
                                            disabled={!canPreviousPage}
                                        >
                                            <button
                                                aria-controls="dataTable"
                                                data-dt-idx="0"
                                                tabIndex="0"
                                                className="page-link"
                                                onClick={() => previousPage()}
                                            >
                                                Anterior
                                            </button>
                                        </li>

                                        <li className="paginate_button page-item page-link">
                                            {pageIndex + 1} de {pageOptions.length}
                                        </li>

                                        <li
                                            className="paginate_button page-item next"
                                            id="dataTable_next"
                                        >
                                            <a
                                                onClick={() => nextPage()}
                                                aria-controls="dataTable"
                                                data-dt-idx="7"
                                                tabIndex="0"
                                                className="page-link"
                                            >
                                                Próximo
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <a class="back-to-topAdmi d-flex align-items-center justify-content-center active" onClick={showModal}>
                <i className="bi bi-pencil-square"></i>
            </a>
            <Modal
                title="Movimiento Caja"
                open={open}
                //onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}

                footer={null}
            >
                <div class="accordion " id="accordionPanelsStayOpenExample border  rounded list-group-item ">
                    <div class="accordion-item bg-white-custom ">
                        <h2 class="accordion-header bg-white-custom ">
                            <button
                                class="accordion-button bg-light flex  text-dark"
                                type="button"

                                aria-expanded="true"

                            >
                                <strong className="pr-1">Registro Manual </strong>
                            </button>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseOne"
                            class="accordion-collapse collapse show bg-white text-dark"
                        >
                            <div class="accordion-body ">

                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label">Monto:</label>
                                    <div className="col-sm-6 input-group">
                                        <span class="input-group-text">$</span>
                                        <input
                                            className="form-control col-sm-9"
                                            type="number"
                                            value={monto}
                                            onChange={(e) => setMonto(e.target.value)}
                                            required={validate}
                                            step="0.01"
                                        />  </div>
                                </div>

                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label">Tipo:</label>
                                    <div className="col-sm-6">
                                        <select
                                            className="form-select "
                                            value={tipo}
                                            onChange={(e) => setTipo(e.target.value)}
                                            required={validate}
                                        >
                                            <option value="" disabled style={{ color: '#d3d3d3' }}>Elegir Tipo</option>
                                            <option value="ingreso">Ingreso</option>
                                            <option value="egreso">Egreso</option>
                                        </select></div>
                                </div>


                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label">Medio Pago:</label>
                                    <div className="col-sm-6">
                                        <select
                                            className="form-select "
                                            value={pago}
                                            onChange={(e) => setPago(e.target.value)}
                                            required={validate}
                                        >
                                            <option value="" disabled style={{ color: '#d3d3d3' }}>Elegir Medio Pago</option>
                                            <option value="EFECTIVO">Efectivo</option>
                                            <option value="MEPG">Mercado Pago</option>
                                            <option value="TRANSFERENCIA">Transferencia</option>
                                            <option value="TARJETA">Tarjeta</option>
                                        </select></div>
                                </div>



                                {/* 
                          { tipo === "egreso" && <div className="row mb-3">
                                <label className="col-sm-2 col-form-label">Origén Fondos:</label>
                                <div className="col-sm-6">
                                    <select
                                        className="form-select "
                                        value={tipoCaja}
                                        onChange={(e) => setTipoCaja(e.target.value)}
                                        required={validate}
                                    >
                                        <option value="" disabled style={{ color: '#d3d3d3' }}>Elegir origén Fondos</option>
                                        <option value="chica">Caja Chica</option>
                                        <option value="central">Caja Central</option>
                                    </select></div>
                            </div>} */}


                                <div className="center">
                                    <button className=" btn btn-primaryMenu" onClick={(e) => handleSubmit(e)}>
                                        Registrar Movimientos
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </main>
    );
}
