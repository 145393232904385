import React, { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { initMercadoPago, Payment, Wallet } from '@mercadopago/sdk-react';
import AppContext from "../context/AppContext";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";
import ProductoItem from "../view/productItem";


export default function Cart() {
  const navigate = useNavigate();
  const [usuario] = useCookies(['usuario']);
  const { addToCart, cart, deleteCartById, link, modo, setClick, deleteCart, pedidos, api, setTotal, table, total, pago } = useContext(AppContext);
  const [cookies, setCookie, getCookie] = useCookies(['cliente']);
  const [nombre, setNombre] = useState(cookies.cliente?.nombre || '');
  const [email, setEmail] = useState(cookies.cliente?.email || '');
  const [telefono, setTelefono] = useState(cookies.cliente?.telefono || '');
  const [activo, setActivo] = useState(cookies.cliente?.activo || '');
  const [comentario, setComentario] = useState(cookies.cliente?.comentario || '');
  const [direccion, setDireccion] = useState(cookies.cliente?.direcciones?.find(dir => dir.activa) || '');
  const [mesero, setMesero] = useCookies(["mesero"]);
  const [isLoading, setIsLoading] = useState(false);
  const [cupon, setCupon] = useState('');
  const [descuento, setDescuento] = useState('');
  const [idPedido, setIdPedido] = useState('');

  const [mp, setMp] = useState(0);
  const [porcentaje, setPorcentaje] = useState(0);
  let location = useLocation();
  const [preference, setPreference] = useState(false);


  const consumoTotal = () => {

    const reducer = (accumalator, currentValue) => {
      const extraCost = currentValue.extra?.reduce(
        (extraAcc, extraItem) => extraAcc + extraItem.costo,
        0
      ) || 0;

      return accumalator + (currentValue.price + extraCost) * currentValue.qty;
    }; const sum = cart.card.reduce(reducer, 0)
    setTotal(sum);
  }

  const savedSerweb = (porcentaje === 0) ? 0 : '$' + (total / (porcentaje * 100)).toFixed(1);
  const savedSumTotal = (porcentaje === 0) ? total : (Number(savedSerweb.replace('$', '')) + total).toFixed(1);

  localStorage.setItem('serweb', savedSerweb);
  localStorage.setItem('sumTotal', savedSumTotal);

  const serweb = localStorage.getItem('serweb');
  let sumTotal = Number(localStorage.getItem('sumTotal'));

  const currentUrl = window.location.href;
  let approved = currentUrl.includes('approved');
  const rejected = currentUrl.includes('rejected');
  let uno = true;

  useEffect(() => {

    window.scrollTo(0, 0);
    consumoTotal()
    if (total === 0 || total.length < 1) {
      navigate(`/${usuario.usuario.local}/menu`);
    }

    fetchDt();
  }, [total]);


  const onChangeLinkMenu = () => {
    debugger
    setClick();
  }

  const [currentStep, setCurrentStep] = useState(0);

  // Array de pasos
  const steps = ["Pedir", "Pagar", "Disfrutar"];

  // Función para avanzar al siguiente paso
  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };


  const updateProduct = async () => {

    setIsLoading(true);
    try {
      // Construye el cuerpo de la solicitud
      const clienteActivo = cookies.cliente?.direcciones?.find(direccion => direccion.activa) || {};

      const data = {

        pedidos: {
          consumototal: total,
          comentario: comentario || "",
          pago: pago.slice(0, 4),
          modo: modo, // Valor por defecto si 'modo' es opcional
          mesero: mesero?.mesero || "",
          descuento: descuento,
          pedidoproducto: cart.card.map(({ id, qty, comentario, adicionales, extra }) => ({
            producto: id,
            cantidad: qty,
            aclaracion: comentario,
            adicionales: adicionales,
            extra: extra
          })),
        },

        cliente: {

          nombre: cookies.cliente?.nombre || nombre,
          email: cookies.cliente?.email || "",
          telefono: cookies.cliente?.telefono || "",
          subscricion: cookies.cliente?.subscrito || false,
          direccion: clienteActivo?.direccion || "",
          pisodepto: clienteActivo?.piso?.trim() || "",
          observacion: clienteActivo?.observacion || "",
          latitud: clienteActivo?.latitud || "",
          longitud: clienteActivo?.longitud || ""
        }
      };

      // Realiza la solicitud POST
      const response = await fetch(`${api}/api/pedidos/${usuario.usuario.id}/${table}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      // Manejo de respuesta
      const status = await response.status;
      const idpedido = await response.json();
      if (status === 200) {
        nextStep()
        setIdPedido(idpedido)

        if (pago === "MEPG") {
          fetchData(idpedido)
        }

        // Verifica si el modal está activo y lo desactiva
        const modal = document.getElementById(`carrito`);
        if (modal?.classList.contains('active')) {
          modal.classList.remove('active');
          setClick();
        }

        // Actualiza el estado en función de la respuesta
        pedidos(status);
        deleteCart();

        setTotal(consumoTotal);


        notification.open({
          message: 'Se creó el pedido.',
          // description: 'Se agrego el producto. 😋',
          icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
        })


      } else {
        notification.open({
          message: "Ocurrio un error  al crear el pedido😢",
          description: "Intentalo nuevamente o comunicate con un personal.",
          icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,

        });
      }
    } catch (error) {
      notification.open({
        message: "Ocurrio un error al crear el pedido😢",
        description: "Intentalo nuevamente o comunicate con un personal.",
        icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,

      });
      setIsLoading(false);
    } finally {

    }
  };









  const fetchData = async (pedido) => {
    try {


      const data = {
        producto: cart.card.map(producto => {
          return {
            itemId: producto.id,
            itemTitle: producto.nombre,
            itemDescription: producto.nombre,
            itemQuantity: producto.qty,
            currencyId: 'ARS',
            itemUnitPrice: producto.price

          };
        }),
        cliente: usuario.usuario.id,
        pedido: pedido,
        //cliente: { nombre: user.nombre, email: user.email, telefono: user.telefono },
      };


      const response = await fetch(`${api}/api/pay`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify(data),
      });

      const responseData = await response.json();

      setPreference(responseData.preferenceId);


    } catch (error) {
      notification.open({
        message: "Ocurrio un error para conectar con Mercado Pago 😢",
        description: "Intentalo nuevamente o comunicate con un personal.",
        icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,

      });
    }

  };
  const fetchDt = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };

      const response = await fetch(`${api}/api/dt/${usuario.usuario.id}`, requestOptions);
      const result = await response.json();
      setMp(result.mp)
      setPorcentaje(result.porcentaje);
    } catch (error) {
      console.log('Error al obtener el usuario:', error);
    }

  };




  initMercadoPago(mp, { locale: 'es-AR' });




  const customization = {
    /* paymentMethods: {
       creditCard: "all",
       debitCard: "all",
       mercadoPago: "all",
     },*/
    visual: {
      buttonBackground: 'white',
    },
  };



  // const onSubmit = async (
  //   { selectedPaymentMethod, formData }
  // ) => {
  //   // callback llamado al hacer clic en el botón enviar datos
  //   return new Promise((resolve, reject) => {
  //     fetch(`${api}/api/payed`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     })
  //       .then((response) => response.json())
  //       .then((response) => {
  //         // recibir el resultado del pago
  //         resolve();
  //         setUnico(1);
  //       })
  //       .catch((error) => {
  //         // manejar la respuesta de error al intentar crear el pago
  //         reject();
  //       });
  //   });

  // };
  const onError = async (error) => {

    console.log(error);
  };
  const onReady = async () => {

  };


  const handleInputChange = (event) => {
    setCupon(event.target.value); // Actualizar el estado del cupón cuando cambie el valor del input
  };

  const promociones = async () => {

    const data = {
      id: usuario.usuario.id,
      codigo: cupon,
      monto: sumTotal
    }

    try {

      const response = await fetch(`${api}/api/promocion/verificar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify(data),
      });

      if (!response.ok) {
        // Si la respuesta no es exitosa, lanza un error con el texto de la respuesta
        const errorText = await response.text();
        throw new Error(errorText);
      }
      const datas = await response.json();

      if (datas) {
        notification.open({
          message: `Se aplicó descuento $${datas.descuento} 😋`,
          description: `${datas.porcentaje}% OFF 👑 `,
          icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
        })

        setDescuento(datas)
      }

    } catch (error) {
      notification.open({
        message: "Ocurrio un error 😢",
        description: `${error.message}`,
        icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
      });
      console.log(error)
    }
  }

  return (
    <section id="contact" className="sectionMenu section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-title pb-2">
          <h2>Pedido y Pagos</h2>
          <p>Pago</p>
        </div>
      </div>

      <div className="container " data-aos="fade-up">

        <div class="wizard-progress mb-4">
          {steps.map((step, index) => {
            // Determina la clase según el estado del paso
            const stepClass =
              index < currentStep
                ? "step complete"
                : index === currentStep
                  ? "step in-progress"
                  : "step";
            return (
              <div key={index} className={stepClass}>
                {step}
                <div className="node"></div>
              </div>
            );
          })}
        </div>
        {(!idPedido && !rejected) && <div className="alert alert-warning section-bg mt-5 pl-0 pr-0 mr-5 " role="alert">
          <h6 className=" pl-0 pr-0">¡Estás a un paso de abonarlo!</h6>
        </div>}
        {rejected ? <div id="subtotal" className="align-right">
          <h4 className="center"><i className="bi bi-x-circle"></i>Pago Rechazado</h4>
        </div> : <></>}
        <br></br>

        <div id="cart-add" className="section-p1 center">



          <div id="subtotal" className="align-right">


            <div className="flex">  <h4 className="center pl-5">Tu Resumen </h4>

           {  !idPedido && <span class="material-icons-outlined" onClick={onChangeLinkMenu}>
                drive_file_rename_outline</span>}
            </div>
            <table className="totales">
            <tr>
                <td>Forma de Pago</td>
                <td className="precios">{pago}</td>
              </tr>
              <tr>
                <td>Subtotal</td>
                <td className="precios">${typeof total === 'number' ? total.toFixed(2) : ''}</td>
              </tr>
              <tr>
                <td><h6>Servicio web </h6></td>

                {/* <OverlayTrigger placement="top" overlay={tooltip}>
      <i className="bi bi-info-circle"></i>
      </OverlayTrigger> */}
                <td className="precios"><h6>{serweb}</h6> </td>
              </tr>
              <tr>
                <td><strong>Total</strong></td>
                <td className="precios">
                  {descuento ? (
                    <>
                      <span style={{ textDecoration: "line-through", marginRight: "10px", fontSize: "14px" }}>
                        ${sumTotal.toFixed(2)}
                      </span>
                      <strong>${(sumTotal - descuento.descuento).toFixed(2)}</strong>
                    </>
                  ) : (
                    <strong>${sumTotal.toFixed(2)}</strong>
                  )}                  </td>
              </tr>
           
            </table>

            {!descuento || idPedido && <div className="grid martop4">
              <span className=""> <input type="text" placeholder="Ingresa tu Cupón" className='cupon' value={cupon} onChange={handleInputChange} />
                <button className="buttonMenu " onClick={() => promociones()}>Agregar</button></span>
            </div>}

            {!isLoading ? <button className="normal second topbot" onClick={() => updateProduct()}>Confirmar Pedido</button>
              : preference ?

                <Wallet initialization={{ preferenceId: preference, redirectMode: 'modal', auto_return: "approved" }}
                  customization={customization} />
                : !idPedido || pago === "MEPG" ?
                  <button className="normal second center"><div className="loading d-block"></div></button>
                  : <button className="normal second topbot" data-bs-toggle="modal" data-bs-target='#exampleModalFP'>Formas de Pagos</button>}




            {/*
           
           // <Payment
            //   initialization={initialization}
            //   customization={customization}
            //   onSubmit={onSubmit}
            //   onReady={onReady}
            //   onError={onError}
          
            // />   
    : <> </>*/}





          </div>

          <div className="modal fade" id="exampleModalFP" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content modal-contentMenu">
                <div className="modal-header">
                  <span data-bs-dismiss="modal" className="btn-close material-icons-outlined" translate="no">close</span>

                </div>
                <div className="modal-body modal-bodyMenu">

                  <form >


                    <div className="accordion" id="accordionExample">
                     
                     {pago !== "TRANSFERENCIA" &&
                     <>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Pago Efectivo
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            Al retirar el pedido podrás pagar con efectivo.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Pago Tarjeta Debito-Crédito
                          </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            Al retirar el pedido podrás pagar con tu tarjeta de debito o crédito.
                          </div>
                        </div>
                      </div>
                 </>}
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Transferencias - Envio Dinero MP
                          </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            Sólo se acepta transferencia directa desde el mismo banco emisor al banco receptor.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>

              </div></div>

          </div>

          {/* <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-contentMenu">
              <div className="modal-header">
              <span data-bs-dismiss="modal" className="btn-close material-icons-outlined" translate="no">close</span>

            </div>
          
               
              </div></div>

          </div> */}
          <div id="context">
            <ul id="carrito" className={link ? "active" : ""}>
              <li className="cabezal">
                <span id="closeCar" onClick={onChangeLinkMenu} ><span translate="no" className="material-icons-outlined">
                  close
                </span></span> <span href="" id="title">
                  {mesero && mesero.mesero ? <h4>Pedido - Mesa <strong>{table}</strong>  </h4> : <h4>Mi Pedido</h4>}
                </span> <span ></span>
              </li>

              <ul id="pedido" >
                <ProductoItem></ProductoItem>
              </ul>

            </ul>
            {/* <div className={link ? "opacar visible" : "opacar "}></div> */}
          </div>

        </div>
      </div>
    </section>
  )
}