import React, { useContext, useState, useEffect } from 'react';
import { Link, Redirect, useHistory, redirect, useNavigate } from "react-router-dom";
import AppContext from '../context/AppContext';
import { useCookies } from 'react-cookie';
import { notification, Radio, Modal } from 'antd';
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';


export default function ProductoItemAdmin({ props }) {

  const { addToCart, cart, deleteCartById, restToCart, link, setClick, deleteCart, pedidos, api, edit, comentario, setComentario } = useContext(AppContext);
  const [usuario, setUsuario] = useCookies(['usuarioadmi']);
  const [data, setData] = useState(false);
  const [selectedMesa, setselectedMesa] = useState(null);
  const [cookies, setCookie] = useCookies(['cliente']);
  const [tipo, setTipo] = useState('Local');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ok, setOk] = useState(0);
  const navigate = useNavigate();
  var modal = document.getElementById('exampleModal');

  const [selectedOptionPago, setSelectedOptionPago] = useState([]);
  const mesa = 0;

  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    const handleBackButton = (event) => {
      const modal = document.getElementById(`carrito`);

      if (modal.classList.contains('active')) {
        modal.classList.remove('active');
        modal.classList.remove('show'); // ocultar el modal
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');

        document.body.style.removeProperty('overflow');
        document.body.style.removeProperty('padding-right');
        setClick();
      }

    };

    fetchMesas()

    // win9dow.history.pushState(null, '', window.location.href); // Agrega una nueva entrada de historial para evitar volver atrás
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const hideModal = () => {
    const modal = document.getElementById('exampleModal'); // obtener el modal
    const backdrop = document.querySelector('.modal-backdrop'); // obtener el backdrop
    debugger
    if (modal) {

      modal.classList.remove('show'); // ocultar el modal
      modal.classList.remove('hide');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');

      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('padding-right');
    }
    if (backdrop) {
      backdrop.remove(); // eliminar el backdrop
    }
  };




  const sumaTotal = () => {
    const reducer = (accumalator, currentValue) => {
      const extraCost = currentValue.extra?.reduce(
        (extraAcc, extraItem) => extraAcc + extraItem.costo,
        0
      ) || 0;

      return accumalator + ((currentValue.price || currentValue.precio) + extraCost) * currentValue.qty;
    };
    const sum = cart.card.reduce(reducer, 0)
    return sum;
  }

  let qty = 0;

  const revomeProdu = produ => {
    deleteCartById(produ);

  }

  const handleOptionChange = (opcion) => {
    setSelectedOptionPago(opcion);
    console.log(selectedOptionPago)
  };


  const addCliente = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const user = {
      nombre: formData.get('name'),
      email: formData.get('email'),
      telefono: formData.get('telefono'),
      subscrito: formData.get('subscrito'),
    };

    setCookie('cliente', {
      nombre: user.nombre,
      telefono: user.telefono.toString(),
      email: user.email,
      subscrito: user.subscrito,
    }, { path: '/', maxAge: 2592000 });
    //,  consumoTotal: consumoTotal 

    setIsLoading(true);
    try {
      const data = {
        cliente: { nombre: user.nombre, email: user.email, telefono: user.telefono, subscrito: user.subscrito },
      };


      const response = await fetch(`${api}/api/pedidos/cliente`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify(data),
      });
      const datas = await response.status;

      if (datas === 200) {
        setOk(datas)
        const modal = document.getElementById(`carrito`);

        modal.classList.remove('active');

        setClick();

        updateProduct(cart.card, sumaTotal(), data, selectedOptionPago);
        hideModal(); // cerrar el modal
      }

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // desactivar el indicador de carga
    }
  }

  const updateProduct = async (event, metapago) => {
    event.preventDefault();
    const formElement = document.getElementById('myForm');

    const formData = new FormData(formElement);
    if (!formData.get('name') && !edit) {
      notification.open({
        message: 'Escribir Nombre del Cliente ',
        //description: ' Debes seleccionar entre los ingrediente requeridos.',
        icon: <InfoCircleOutlined style={{ color: '#fd7e14' }} />,
      });
      return;
    }
    const user = {
      nombre: formData.get('name'),
      telefono: formData.get('telefono'),
      comentario: formData.get('comentario'),
    }

    setIsLoading(true); // activar el indicador de carga

    try {
      const data = {
        pedidos: {
          consumototal: sumaTotal(),
          comentario: comentario || "",
          pago: metapago,
          modo: tipo,
          mesero: "Caja",
          mesa: selectedMesa,
          pedidoproducto: cart.card.map(({ id, qty, comentario, adicionales, extra }) => ({
            producto: id,
            cantidad: qty,
            aclaracion: comentario,
            adicionales: adicionales,
            extra: extra
          })),
          ...(edit && { id: edit })
        },
        cliente: { nombre: user.nombre, telefono: user?.telefono || "" },
      };

      const response = await fetch(`${api}/api/pedidos/${edit && 'editar/'}${usuario.usuarioadmi.id}/${selectedMesa}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify(data),
      });
      const datas = await response.status;


      pedidos(datas);
      deleteCart();
      setOk(datas)
      setClick();
      setComentario('');
      setIsModalVisible(false)
      const modal = document.getElementById('menuModal');
      const editmodal = document.getElementById('editModal');

      if (modal) {

        modal.classList.remove('active');

        modal.classList.remove('show'); // ocultar el modal
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');

        document.body.style.removeProperty('overflow');
        document.body.style.removeProperty('padding-right');
        const backdrop = document.querySelector('.modal-backdrop');
        backdrop.remove()
      }

      if (editmodal) {

        editmodal.classList.remove('active');

        editmodal.classList.remove('show'); // ocultar el modal
        editmodal.classList.remove('hide');
        editmodal.style.display = 'none';
        document.body.classList.remove('modal-open');

        document.body.style.removeProperty('overflow');
        document.body.style.removeProperty('padding-right');
        const backdrop = document.querySelector('.modal-backdrop');
        backdrop.remove()
      }
      hideModal();
      notification.open({
        message: `Se ${edit ? 'editó' : 'creó'} el pedido.`,
        // description: 'Se agrego el producto. 😋',
        icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
      })


    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // desactivar el indicador de carga
    }
  };


  const options = [
    {
      label: 'LOCAL',
      value: 'Local',
    },
    {
      label: 'TAKE AWAY',
      value: 'Take Away',
    },
    {
      label: 'DELIVERY',
      value: 'Delivery',
    },
  ];


  const fetchMesas = async () => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(`${api}/api/mesero/${usuario?.usuarioadmi.id}`, requestOptions);


      const data = await response.json();
      setData(data);


    } catch (error) {
      console.error("Error fetching mesas:", error);

    }
  };



  const handleMesaClick = (mesa) => {
    setselectedMesa(mesa.mesa);
    /*setMesa(mesa);
    setTable(mesa.mesa);*/
  };

  return (
    <>
      {
        cart.card.map((props) => (

          <li id="cart" className="" key={props.id} style={{ listStyleType: 'none' }}>
            {props.img && <div className="photo"><img src={props.img} className='height-20' loading="lazy" /></div>}
            <div className="boxes" >
              <div className="detalles name">
                <h6>{props.nombre}</h6>
              </div>
              <div className="detalles interno center flex">
                <div className='qty'>
                  <span onClick={() => {
                    if (props.qty == 1) return;
                    // setShowError(true);
                    restToCart(props, -1);
                  }}
                    // disabled={qty - props.qty <= 0 ? true :  false} 
                    className="material-symbols-outlined">
                    <i className="bi bi-dash-circle"></i>
                  </span>

                  <span id="cantidad">{props.qty} </span>

                  {/* onClick={() => addProduct(-1)}
                         disabled={qty === initial ? true : null} */}

                  <span className="material-symbols-outlined "
                    onClick={() => {
                      if (props.qty === props.stock) return;
                      addToCart(props, 1);


                    }} disabled={props.stock - qty === 0 ? true : null}>
                    <i className="bi bi-plus-circle"></i>
                  </span>

                </div>

                <h6 className="precio ml-4">${(((props.price || props.precio) +
                  (props.extra?.reduce((extraAcc, extraItem) => extraAcc + extraItem.costo, 0) || 0))
                  * props.qty).toFixed(2)}</h6>
                <div className="delete ml-5" onClick={() => revomeProdu(props)} >

                  <i class="bi bi-trash3"></i>
                </div>
              </div>
              { //showError && <p className='error-message'>No se puede restar más del stock disponible</p>
              }
            </div>

          </li>))}


      <li id="comprar" className=' ml-0 pl-0' style={{ bottom: '40px' }}>
        <h4 className="subtotal ml-0 pl-0">Subtotal</h4>
        <h4 className="subtotal cartTotal">${sumaTotal().toFixed(2).slice(0, -3)}</h4>
        <h6 className="decimal text-white">{sumaTotal().toFixed(2).slice(-2)}</h6>

        <div id="comprar" className="duo  ml-0 pl-0">





          <button className="normal end second normal rounded-4 w-80 pb-0  ml-0 " id="add"  onClick={() => setIsModalVisible(true)}>
            <h3>{edit ? 'Editar' : 'Terminar'} Pedido</h3>
          </button>

          <Modal
        title="Confirmar Datos"
        open={isModalVisible}
        onClose={false}
        footer={null} // Oculta los botones del modal
        onCancel={() => setIsModalVisible(false)}
        zIndex={2000} // Asegura que el modal esté por encima de otros
        getContainer={false}
      >
          <form id="myForm">
                    {!edit && <><div className="row">
                      <h5 className='text-white'>Datos para mejorar la experiencia</h5>
                      <div className=" form-group">
                        <input type="text" name="name" className="form-control" id="name" placeholder="Nombre Cliente" required />
                      </div>
                    </div>
                      <div className=" form-group">
                        <input type="number" className="form-control" name="telefono" id="celular2" placeholder="Celular Cliente" pattern="[0-9]{1,10}" />
                      </div></>}

                    <div className="form-group mt-3">
                      <textarea className="form-control" name="comentario" rows="2" placeholder="Aclaración" value={comentario} onChange={(e) => setComentario(e.target.value)} ></textarea>
                    </div>
                    <div className=" mt-3 mb-3">

                      <Radio.Group
                        block
                        options={options}
                        defaultValue="Local"
                        optionType="button"
                        buttonStyle="solid"

                        onChange={(e) => { setTipo(e.target.value); }}
                      />

                    </div>
                    {tipo === "Local" && <div className="form-group mt-3   ">

                      <h6 className=''>Seleccionar Mesa</h6>
                      <div className='flex center gap-2  justify-content-center align-items-center'>
                        {data.mesas && (!selectedMesa ?
                          data.mesas
                            .map((mesa, index) => (
                              <div
                                key={index}
                                className={`tablesmall ${selectedMesa === mesa.mesa ? "active" : ""
                                  }`}
                                style={{ height: '25px !important' }}
                                onClick={() => handleMesaClick(mesa)}
                              >
                                <h5>{mesa.mesa}</h5>
                              </div>
                            ))
                          : data.mesas
                            .filter(mesa => mesa.mesa === selectedMesa)
                            .map((mesa, index) => (
                              <div
                                key={index}
                                className={`tablesmall ${selectedMesa === mesa.mesa ? "active" : ""
                                  }`}
                                onClick={() => handleMesaClick(0)}
                              >
                                <h5>{mesa.mesa}</h5>
                              </div>
                            )))}
                      </div>
                    </div>}
                    {/*       <div className="form-group mt-3">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Email (opcional)" value={email} onChange={(e) => setEmail(e.target.value)} />

                </div>    <div className="my-3">

                  <input type="checkbox" name="subscrito" />
                  <h6>Subscribirme para recibir promociones</h6>

                </div> */}

                    {/* <div >
                <div className="loading d-block"></div>
                <div className="error-message"></div>
              </div> */}

                    {
                      isLoading ? <button className="normal end second"><div className="loading d-block"></div></button> :
                        <button type="button" className="normal   rounded-4 w-80 pb-1"><div className="dropup-center dropup">
                          <h3 className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Medio de Pago</h3>
                          <ul className="dropdown-menu metopago bg-light border-white">
                            <li> <a className="dropdown-item primaryMenu" onClick={(event) => updateProduct(event, 'EFECTIVO')}><i className="bi bi-coin"></i>Efectivo</a></li>
                            <li> <a className="dropdown-item primaryMenu" onClick={(event) => updateProduct(event, 'TARJETA')}><i className="bi bi-credit-card-2-front"></i>Débito/Crédito</a></li>
                            <li> <a className="dropdown-item primaryMenu" onClick={(event) => updateProduct(event, 'TRAN')}><i class="bi bi-bank2"></i>Transferencia</a></li>
                            <li><hr className="dropdown-divider"></hr></li>
                            <li> <a className="dropdown-item primaryMenu" onClick={(event) => updateProduct(event, 'MEPG')}><svg width="27" height="27" stroke="black" strokeWidth="2" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M40.976 30.646a5.276 5.276 0 0 1-2.172-2.034a54.661 54.661 0 0 1-8.748 1.017c-3.7 0-6.687-.176-5.467-3.624s4.458-10.556 5.593-11.986s2.686-3.24 3.449-3.155c.946.106 2.715 1.284 2.516 2.034c-.189.715-1.128 2.277-2.747 1.165" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M32.864 14.84a8.134 8.134 0 0 1 1.293-1.641m1.783.207c.567-.144 1.64.582 1.034 1.328a4.778 4.778 0 0 1-2.817 1.324c-.625.056-2.797-.02-2.797-.02c-.925 1.597-.715 4.037-.8 6.182a9.346 9.346 0 0 1-.882 3.659c3.7-1.725 10.009-3.029 13.822-2.398M7.024 30.646a5.276 5.276 0 0 0 2.172-2.034a54.661 54.661 0 0 0 8.748 1.017c3.7 0 6.687-.176 5.467-3.624s-4.458-10.556-5.593-11.986s-2.686-3.24-3.449-3.155c-.946.106-2.715 1.284-2.516 2.034c.189.715 1.128 2.277 2.747 1.165" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M15.136 14.84a8.134 8.134 0 0 0-1.292-1.641m-1.784.207c-.567-.144-1.64.582-1.034 1.328a4.778 4.778 0 0 0 2.818 1.324c.624.056 2.796-.02 2.796-.02c.925 1.597.715 4.037.8 6.182a9.346 9.346 0 0 0 .882 3.659C14.622 24.153 8.313 22.85 4.5 23.48m27.681-11.738a27.566 27.566 0 0 0-16.364 0" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M10.917 13.99C6.976 16.46 4.5 20.03 4.5 24c0 7.456 8.73 13.5 19.5 13.5S43.5 31.456 43.5 24c0-3.97-2.476-7.54-6.417-10.01" /></svg> Mercado Pago</a>
                            </li>
                          </ul>
                        </div>
                        </button>
                    }
                  </form>
      </Modal>
       


          {/* <button type="button" className="normal end yellow  ml-0"><div className="dropup-center dropup">
                  <h3 className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Realizar Pedido</h3>
                  <ul className="dropdown-menu metopago">
                    <li> <a className="dropdown-item primaryMenu" onClick={() => updateProduct(cart.card, sumaTotal(), cookies, 'EFECTIVO')}><i className="bi bi-coin"></i>Efectivo</ a></li>
                    <li> <a className="dropdown-item primaryMenu" onClick={() => updateProduct(cart.card, sumaTotal(), cookies, 'TARJETA')}><i className="bi bi-credit-card-2-front"></i>Débito/Crédito</ a></li>
                    <li><hr className="dropdown-divider"></hr></li>
                    <li> <a className="dropdown-item primaryMenu" onClick={() => updateProduct(cart.card, sumaTotal(), cookies, 'MEPG')}><svg width="27" height="27" stroke="black" strokeWidth="2" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M40.976 30.646a5.276 5.276 0 0 1-2.172-2.034a54.661 54.661 0 0 1-8.748 1.017c-3.7 0-6.687-.176-5.467-3.624s4.458-10.556 5.593-11.986s2.686-3.24 3.449-3.155c.946.106 2.715 1.284 2.516 2.034c-.189.715-1.128 2.277-2.747 1.165" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M32.864 14.84a8.134 8.134 0 0 1 1.293-1.641m1.783.207c.567-.144 1.64.582 1.034 1.328a4.778 4.778 0 0 1-2.817 1.324c-.625.056-2.797-.02-2.797-.02c-.925 1.597-.715 4.037-.8 6.182a9.346 9.346 0 0 1-.882 3.659c3.7-1.725 10.009-3.029 13.822-2.398M7.024 30.646a5.276 5.276 0 0 0 2.172-2.034a54.661 54.661 0 0 0 8.748 1.017c3.7 0 6.687-.176 5.467-3.624s-4.458-10.556-5.593-11.986s-2.686-3.24-3.449-3.155c-.946.106-2.715 1.284-2.516 2.034c.189.715 1.128 2.277 2.747 1.165" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M15.136 14.84a8.134 8.134 0 0 0-1.292-1.641m-1.784.207c-.567-.144-1.64.582-1.034 1.328a4.778 4.778 0 0 0 2.818 1.324c.624.056 2.796-.02 2.796-.02c.925 1.597.715 4.037.8 6.182a9.346 9.346 0 0 0 .882 3.659C14.622 24.153 8.313 22.85 4.5 23.48m27.681-11.738a27.566 27.566 0 0 0-16.364 0" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M10.917 13.99C6.976 16.46 4.5 20.03 4.5 24c0 7.456 8.73 13.5 19.5 13.5S43.5 31.456 43.5 24c0-3.97-2.476-7.54-6.417-10.01" /></svg> Mercado Pago</ a>
                    </li>
                  </ul>
                </div>
                </button> 
                */}

        </div>
      </li>



    </>
  )

}