import React, { useContext, useEffect, useMemo, useState } from "react";
import { isPointWithinRadius, getDistance } from "geolib";
import { Alert, Button, Toast } from "react-bootstrap";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { notification } from "antd";
import axios from "axios";
import { useCookies } from "react-cookie";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FastForwardFilled,
} from "@ant-design/icons";
import { ErrorResponse } from "@remix-run/router";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../context/AppContext";

export default function Ubicacion() {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [ok, setOk] = useState(false);
  const [selectedLatLng, setSelectedLatLng] = useState(null);
  const [address, setAddress] = useState("");
  const [newaddress, setNewaddress] = useState(true);
  
  const [usuario, setUsuario] = useCookies(["usuario"]);
  const [cookies, setCookie, getCookie] = useCookies(["cliente"]);
  const { estado } = useContext(AppContext);

  const existingDirecciones = cookies.cliente?.direcciones || [];

  const [direccion, setDireccion] = useState(
    cookies.cliente?.direcciones || ""
  );
  const [latitud, setLatitud] = useState(cookies.cliente?.latitud || "");
  const [longitud, setLongitud] = useState(cookies.cliente?.longitud || "");
  const navigate = useNavigate();
  const key = `open${Date.now()}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addCliente = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    const newAddress = {
      direccion: address,
      piso: formData.get("piso"),
      depto: formData.get("depto"),
      observacion: formData.get("observacion"),
      latitud: selectedLatLng.lat,
      longitud: selectedLatLng.lng,
    };

    debugger

    const existingCliente = cookies?.cliente || { direcciones: [] };
    const existingDirecciones = existingCliente.direcciones || [];
   
    // Desactivar todas las direcciones existentes
      const direccionesDesactivadas = existingDirecciones.map(direccion => ({
        ...direccion,
        activa: false,
      }));

      direccionesDesactivadas.push({
      direccion: newAddress.direccion,
      piso: newAddress.piso + " " + newAddress.depto,
      observacion: newAddress.observacion,
      latitud: newAddress.latitud,
      longitud: newAddress.longitud,
      activa: true
    });

    setCookie(
      "cliente",
      {
        ...existingCliente,
        direcciones: direccionesDesactivadas,
      },
      { path: "/", maxAge: 2592000 }
    );

    navigate(`/${usuario.usuario.local}/menu/delivery`);
  };

  const MyAddress = (selectedAddress, lat, lon) => {
    const existingCliente = cookies?.cliente || { direcciones: [] };
    const existingDirecciones = existingCliente.direcciones || [];
   const direccionesActualizadas = existingDirecciones.map(direccion => ({
    ...direccion,
    activa: direccion.direccion === selectedAddress.direccion && direccion.piso === selectedAddress.piso ? true : false,
  }));
    const nuevoCliente = {
      ...existingCliente,
      direcciones: direccionesActualizadas,
    };
  
    setCookie("cliente", nuevoCliente, { path: "/", maxAge: 2592000 });

    setAddress(selectedAddress);
    setSelectedLatLng({ lat: lat, lng: lon });
    setCurrentLocation({ lat, lon });
    setNewaddress(true);
    checkLocation();
  };
  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          //ubicacion = position.coords;
          //checkLocation();

          try {
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            );
            const data = await response.json();
            if (data) {
              let number = data.address.house_number;
              const state =
                data.address.state === "Ciudad Autónoma de Buenos Aires"
                  ? "CABA"
                  : data.address.state;
              if (number === undefined) {
                number = "";
                notification.open({
                  message: "Error al obtener Ubicación",
                  description: "Especificar la dirección",
                  icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
                });
              } else {
                setSelectedLatLng({ lat: latitude, lng: longitude });
                setCurrentLocation({ latitude, longitude });
                setAddress(
                  data.address.road +
                    " " +
                    number +
                    ", " +
                    data.address.suburb +
                    ". " +
                    state
                );
              }
              // ', ' + data.address.state_district +
            } else {
              notification.open({
                message: "Notificación",
                description: "Dirección desconocida.",
                icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
              });
              setAddress("Dirección desconocida");
            }
          } catch (error) {
            notification.open({
              message: "Notificación",
              description: "Error al obtener la dirección.",
              icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
            });
            // window.location.replace('/error')
          }
        },
        (error) => {
          console.error("Error al obtener la ubicación:", error);
          setShowAlert(true);
        }
      );
    } else {
      console.error("El navegador no admite la geolocalización.");
      setShowAlert(true);
    }
  };

  const handleSelectAddress = (selectedAddress, lat, lon) => {
    setAddress(selectedAddress);
    setSelectedLatLng({lat: lat, lng: lon });
    setCurrentLocation({ lat, lon })
    setResults([]);
    //checkLocation()
  };

  const checkLocation = () => {
    const embeddedLink = usuario.usuario?.map;

    // Extraer las coordenadas de latitud y longitud del enlace

    if (selectedLatLng && embeddedLink) {
          const regex = /!2d([-+]?\d+\.\d+)!3d([-+]?\d+\.\d+)/;
    const match = embeddedLink.match(regex);

    let latitude = parseFloat(match[2]);
    let longitude = parseFloat(match[1]);

    const locationToCheck = { latitude: latitude, longitude: longitude };
    const center = currentLocation;
      const distance = getDistance(center, locationToCheck);
      console.log("Distancia:", distance, "metros");

      const radius = (Number(usuario.usuario.delivery) * 100);
      const isWithinRange = isPointWithinRadius(locationToCheck, center, radius);

      if (isWithinRange) {
        // notification.open({
        //   message: 'Notificación',
        //   description: 'La ubicación está dentro del rango.',
        //   icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
        // });
        setOk(true);
        notification.destroy();
      } else {
        notification.open({
          message: "Fuera del rango 😢",
          description: "La ubicación está fuera de la zona de entrega.",
          icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
          key: key,
          duration: 0,
        });
        setOk(false);
      }
    } else {
      notification.open({
        message: 'No disponible ¡Lo sentimos! 😢',
        description: 'Delivery no está disponible en este momento.',
        icon: <CloseCircleOutlined style={{ color: '#bd0003' }} />,
      });
      setOk(false);
      return
      //alert('La ubicación actual no está disponible.');
    }
  };

  const [results, setResults] = useState([]);

  const handleSearch = async (inputAddress) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${inputAddress}&countrycodes=AR&addressdetails=1&order=importance&limit=5`
      );
      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error("Error en la búsqueda de direcciones:", error);
    }
  };
  const handleChange = (e) => {
    const inputAddress = e.target.value;
    const words = inputAddress.split(" ");

    // Verifica si hay al menos dos palabras
    if (words.length >= 1) {
      setAddress(inputAddress);
      handleSearch(inputAddress);
    }
  };

  const handleClear = () => {
    setAddress("");
    setResults([]);
  };

  const mapKey = useMemo(
    () =>
      selectedLatLng
        ? `${selectedLatLng.lat}-${selectedLatLng.lon}`
        : "default",
    [selectedLatLng]
  );

  const [activeButton, setActiveButton] = useState("button1");

  const handleClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  useEffect(() => {
    if (currentLocation) {
      console.log("currentLocation ha sido actualizado:", currentLocation);
      checkLocation();
    }
  }, [currentLocation]);

  return (
    <section id="menu" className="contact sectionMenu menu section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Ubicación</h2>
          <p>Zona de Reparto</p>
        </div>
        <center className="grid">
          <div>
            <div className="btn-group">
              <button
                type="button"
                className={`btn ${
                  activeButton === "button1"
                    ? "btn-primaryMenu"
                    : "btn-secondary sombra"
                }`}
                onClick={() => handleClick("button1")}
              >
                <i className="bi-shop-window"></i> Take Away
              </button>
              <button
                type="button"
                className={`btn ${
                  activeButton === "button2"
                    ? "btn-primaryMenu"
                    : "btn-secondary sombra"
                }`}
                onClick={() => handleClick("button2")}
              >
                <i className="bi bi-rocket-takeoff"></i> Delivery
              </button>
            </div>
          </div>
          {activeButton === "button2" && (
            <>
              {showAlert && (
                <Alert
                  variant="danger"
                  onClose={() => setShowAlert(false)}
                  dismissible
                >
                  No se pudo obtener la ubicación actual. Por favor, asegúrese
                  de permitir el acceso a la ubicación.
                </Alert>
              )}

              {selectedLatLng && !newaddress ? (
                <MapContainer
                  className="margin"
                  key={mapKey}
                  center={selectedLatLng}
                  zoom={18}
                  style={{ height: "200px", width: "80%" }}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  <Marker position={selectedLatLng} />
                </MapContainer>
              ) : (
                <></>
              )}
              <div className="grid">
                {direccion && newaddress ? (
                  <>
                    <div className="flex center align-items-end">
                      
                      <span className=" primaryMenu book-a--btn  mt-4">
                        Mis direcciones
                      </span>

                      
                    </div>

                    {direccion.map((direccionItem, index) => (
                      <div
                        key={index}
                        className="form-check martop4 focus"
                        onClick={() =>
                          MyAddress(direccionItem, direccionItem.latitud, direccionItem.longitud)
                        }
                      >
                        <input
                          className="form-check-input focus"
                          type="radio"
                          name="flexRadioDefault"
                          id={`flexRadioDefault${index}`}
                        />
                        <label
                          className="form-check-label focus"
                          htmlFor={`flexRadioDefault${index}`}
                        >
                          {direccionItem.direccion}{" "}
                          <h6>
                            {direccionItem.piso}{" "}
                            {direccionItem.observacion &&
                              `| ${direccionItem.observacion.toUpperCase()}`}
                          </h6>
                        </label>
                      </div>
                    ))}
                      <div className="text-center d-block  btns flex">
                      {(ok &&  estado === "Abierto" ) && (
                        <Link to={`/${usuario.usuario.local}/menu/delivery`}>
                          {" "}
                          <button className="buttonMenu">
                            <i className="bi bi-collection"></i> Elegir Menú
                          </button>
                        </Link>
                      )}
                    </div>
                  <div className="text-center d-block martop4">
                    <button
                        className="buttonMenu btn-primaryMenu"
                        type="submit"
                        onClick={() => setNewaddress(false)}
                      >
                      <i className="bi bi-geo-alt-fill"></i> Nueva Dirección
                      </button>
                      
                  </div>
                    {/*      <div className="form-check" onClick={() => setNewaddress(false)}>
             <input className="form-check-input"  type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="opcion2" />
             <label className="form-check-label" for="flexRadioDefault2"    >
              Nueva Dirección
             </label>
           </div> 
 */}
                  
                  </>
                ) : (
                  <div className="flex  mt-4">  
                 
                    <button
                      onClick={getCurrentLocation}
                      className=" buttonMenu btn-primaryMenu"
                    >
                      <i className="bi bi-geo-alt-fill "></i> Usar ubicación
                      actual
                    </button>
                  
                  </div>
                )}
              </div>

              {direccion && newaddress ? (
                <></>
              ) : (
                <div className="col-lg-8 mt-lg-0">
                  <form
                    onSubmit={(event) => addCliente(event)}
                    className="php-email-form"
                  >
                    <div className="row">
                      <div className="mt-3 mt-md-0 flex">
                        <input
                          type="text"
                          className="form-control textCenter"
                          onChange={handleChange}
                          value={address}
                          name="fullAddress"
                          id="ubic"
                          placeholder="TU DIRECCIÓN"
                          autoComplete="off"
                          required
                        />
                        {address && (
                          <button
                            type="button"
                            className="btnCircular"
                            onClick={handleClear}
                          >
                            x
                          </button>
                        )}
                      </div>
                      <ul className="direccion">
                        {results.map((result) => {
                          const formattedAddress = `${
                            result.address.road || ""
                          } ${result.address.house_number || ""}, ${
                            result.address.suburb || ""
                          }, ${
                            result.address.state ===
                            "Ciudad Autónoma de Buenos Aires"
                              ? "CABA"
                              : result.address.state
                          }`;
                          const isValidAddress = formattedAddress.trim() !== "";

                          return (
                            isValidAddress && (
                              <li
                                key={result.place_id}
                                onClick={() =>
                                  handleSelectAddress(
                                    `${result.address.road} ${
                                      result.address.house_number
                                    }, ${result.address.suburb}. ${
                                      result.address.state ===
                                      "Ciudad Autónoma de Buenos Aires"
                                        ? "CABA"
                                        : result.address.state
                                    }`,
                                    //, ${result.address.state_district}
                                    result.lat,
                                    result.lon
                                  )
                                }
                              >
                                {formattedAddress}
                              </li>
                            )
                          );
                        })}
                      </ul>
                      <div className=" form-group flex mt-md-0">
                        <div className="col-md-6">
                          <input
                            type="number"
                            name="piso"
                            className="form-control ubic textCenter"
                            id="piso1"
                            placeholder="PISO"
                            required
                          />
                        </div>
                        <div className="col-md-6  ">
                          <input
                            type="text"
                            name="depto"
                            className="form-control ubic textCenter"
                            id="piso2"
                            placeholder="DEPTO / CASA"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group mt-3 mt-md-0">
                        <input
                          className="form-control ubic textCenter"
                          name="observacion"
                          rows="1"
                          placeholder="(CASA, OFICINA, OBS) OPCIONAL"
                        />
                      </div>
                    </div>

                    <div className="text-center d-block martop4">
                      {(ok && estado === "Abierto") && (
                        <button className="buttonMenu" type="submit">
                          <i className="bi bi-collection"></i> Elegir Menú
                        </button>
                      )}
                       {direccion && (
                      <button
                        className="buttonMenu"
                        onClick={() => setNewaddress(true)}
                      >
                        {" "}
                       Direcciones Anteriores
                      </button>
                    )}
                    </div>
                  </form>
                </div>
              )}

              {/* 
          <Button  onClick={checkLocation}>Comprobar ubicación</Button> */}
            </>
          )}
        </center>
      </div>
      {activeButton === "button1" && (
        <div className="container" data-aos="fade-up">
          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Dirección:</h4>
                  <p>{usuario.usuario.direccion}</p>
                </div>

                <div className="open-hours">
                  <i className="bi bi-clock"></i>
                  <h4>Horario:</h4>
                  <p>{estado}</p>
                </div>

                {/* <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p> {usuario.usuario.email} </p>
              </div> */}

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Teléfono:</h4>
                  <p>11-{usuario.usuario.telefono}</p>
                </div>
              </div>
            </div>
            <div className="margin">
              <iframe
                className="mapMenu"
                src={usuario.usuario.map}
                frameborder="0"
                allowfullscreen
                style={{ width: "100%" }}
              ></iframe>
            </div>
          </div>
          <div className="text-center d-block flex center">
        {  estado === "Abierto"  && <Link to={`/${usuario.usuario.local}/menu/takeAway`}>
              <button className="buttonMenu">
                <i className="bi bi-collection"></i> Elegir Menú
              </button>
            </Link>}
          </div>
        </div>
      )}
    </section>
  );
}
