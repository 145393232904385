import { Alert } from "react-bootstrap";
import { isPointWithinRadius, getDistance } from "geolib";
import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import { notification } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { use } from "react";
import { useCookies } from "react-cookie";

export default function Sucrip() {
  const [registroExitoso, setRegistroExitoso] = useState();
  const [data, setData] = useState(false);
  const [showAlert, setShowAlert] = useState();
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordOk, setPasswordOk] = useState();
  const [usuario, setUsuario] = useCookies(['usuarioadmi']);
  const navigate = useNavigate();
  
  const { api } = useContext(AppContext);

  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,12}$/;

  function validatePassword(password) {
    return passwordRegex.test(password);
  }


  function handleSubmit(event) {
    event.preventDefault();

    //const formData = new FormData(event.target);
    /* if (selectedLatLng === null) {
      notification.open({
        message: 'Notificación',
        description: 'Debe seleccionar una dirección',
        icon: <InfoCircleOutlined style={{ color: '#cda45e' }} />,
      });
      handleChangeUbi(address)
      return
    } */
    if (password === password2) {
      if (validatePassword(password)) {
       
        setPasswordsMatch(true);
        setPasswordOk(true);
        handleRegistration(event);
      } else {
       
        setPasswordOk(false);
      }

    } else {
      //setShowAlert(true);
      setPasswordsMatch(false);
      
    }
  }

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    password: "",
    tipo: "",
    local: "",
    direccion: "",
    whatsapp: "",
    telefono: "",
    email: "",
    terminos: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmi = (e) => {
    e.preventDefault();
    console.log("Form submitted", formData);
  };

  const handleRegistration = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    const admi = {
      nombre: formData.get('nombre') + " " + formData.get('apellido'),
      email: formData.get('email'),
      local: formData.get('local'),
      password: formData.get('password')
    }
    const user = {
    
      tipo: formData.get('tipo'),
      local: formData.get('local'),
      map: selectedLatLng ? `https://www.google.com/maps?q=${selectedLatLng.lat},${selectedLatLng.lon}` : null,
      whatsapp: formData.get('whatsapp'),
      telefono: formData.get('telefono'),
      email: formData.get('email'),
      terminos: formData.get('terminos') !== 'on',
      direccion: formData.get('direccion'),
      activo: true,
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ admi, user }),
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const response = await fetch(`${api}/api/usuarios/alta`, requestOptions);
    const data = await response.status;
  
    setData(data);
 
debugger
    if (response.ok) {

      const dataPromise = await response.json();
      setUsuario('usuarioadmi', dataPromise.usuario, { path: '/', maxAge: 840 * 60 });
    


      //handleLoginSuccess(true)
      const expirationTime = new Date().getTime() + 12 * 60 * 60 * 1000; // 4 horas en milisegundos
      localStorage.setItem('registroExitoso', JSON.stringify({ value: true, expirationTime }));
      notification.open({
        message: 'Bienvenido 😀',
        description: 'Registro exitoso',
        icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
      });
      setRegistroExitoso(true);
      navigate('/Admin', { replace: true });

    } else {
      const text = await response.text();
      notification.open({
        message: "Ocurrio un error 😢",
        description: `${text || error.message}`,
        icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
      });

    }
  };

  //----------------------direcion--------------

  const [currentLocation, setCurrentLocation] = useState(null);
  const [ok, setOk] = useState(false);
  const [selectedLatLng, setSelectedLatLng] = useState(null);
  const [address, setAddress] = useState("");
  const [newaddress, setNewaddress] = useState(true);
  const key = `open${Date.now()}`;


  const handleSelectAddress = (selectedAddress, lat, lon) => {
    setAddress(selectedAddress);
    setSelectedLatLng({ lat: lat, lng: lon });
    setCurrentLocation({ lat, lon })
    setResults([]);
    //checkLocation()
  };

  const checkLocation = () => {


    // Extraer las coordenadas de latitud y longitud del enlace

    if (selectedLatLng) {
      const regex = /!2d([-+]?\d+\.\d+)!3d([-+]?\d+\.\d+)/;
      //---***ojo
      const match = selectedLatLng.match(regex);

      let latitude = parseFloat(match[2]);
      let longitude = parseFloat(match[1]);

      const locationToCheck = { latitude: latitude, longitude: longitude };
      const center = currentLocation;
      const distance = getDistance(center, locationToCheck);
      console.log("Distancia:", distance, "metros");

      setSelectedLatLng({ lat: latitude, lng: longitude });

    } else {
      notification.open({
        message: 'No disponible ¡Lo sentimos! 😢',
        description: 'Delivery no está disponible en este momento.',
        icon: <CloseCircleOutlined style={{ color: '#bd0003' }} />,
      });
      setOk(false);
      return
      //alert('La ubicación actual no está disponible.');
    }
  };
  const [results, setResults] = useState([]);

  const handleSearch = async (inputAddress) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${inputAddress}&countrycodes=AR&addressdetails=1&order=importance&limit=5`
      );
      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error("Error en la búsqueda de direcciones:", error);
    }
  };
  const [error, setError] = useState("");
  const handleChangeUbi = (e) => {
    const inputAddress = e.target.value;
    const words = inputAddress.trim().split(/\s+/).length;

    // Verifica si hay al menos dos palabras
    if (words > 1) {
      setError("");
      setAddress(inputAddress);
      handleSearch(inputAddress);
    } else {
      setError("La dirección debe contener al menos calle y altura.");
    }
  };

  const handleClear = () => {
    setAddress("");
    setResults([]);
  };

  /*   useEffect(() => {
      if (password == "") return;
      if (password === password2 && validatePassword(password)) {
        setPasswordsMatch(true);
  
        setPasswordOk(true)
  
      } else {
        //setShowAlert(true);
        setPasswordsMatch(false);
        setPasswordOk(false)
      }
    }, [password, password2]); */

  const handleChangeDire = (e) => {
    setAddress(e.target.value); // Permite que el usuario escriba
  };


  return (

    <div className="w-100 mb-4">
      {registroExitoso === false ?
        <div className="alert alert-danger" role="alert">
          ¡Registro Falló! {data}
        </div> : <></>
      }   {showAlert && (
        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
          Las contraseñas no coinciden.
        </Alert>
      )}


      <section className="  d-flex flex-column align-items-center justify-content-center mb-2">
        <div className="">
          <div className=" justify-content-center">
            <div className="form-v10 ">


              <div className=" page-content">

                <div className="">

                  {/*<div className="pb-1">
                    <h5 className=" pt-2 text-center pb-0 fs-4">Ahora Gratis</h5>
                     <p className="text-center small">Confirma tus datos</p>
                  </div> */}
                  <form className="form-v10-content" onSubmit={handleSubmit}>
                    <div className="form-detail">
                      <div className="form-left">
                        <h2>Tus Datos</h2>

                        <div className="form-group">
                          <div class="form-row form-row-1 " style={{ flexWrap: "nowrap", alignItems: "center" }}>
                            <span class="material-icons-round">
                              face
                            </span>                            <input type="text" name="nombre" placeholder="Tu Nombre" required onChange={handleChange} />
                          </div>
                          <div class="form-row form-row-1">
                            <input type="text" name="apellido" placeholder="Tu Apellido" required onChange={handleChange} />
                          </div>
                        </div>
                        <div class="form-row" style={{ flexWrap: "nowrap", alignItems: "center" }}>
                          <span class="material-icons-round">
                            email
                          </span>                          <input type="text" name="email" id="email" class="input-text" required pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}" placeholder="Tu Email" onChange={handleChange} />
                        </div>

                        {/*      <div className="form-row">
                        <select name="position" value={formData.position} onChange={handleChange}>
                          <option value="">Position</option>
                          <option value="director">Director</option>
                          <option value="manager">Manager</option>
                          <option value="employee">Employee</option>
                        </select>
                      </div> */}
                        <div class="form-row" style={{ flexWrap: "nowrap", alignItems: "center" }}>
                          <span class="material-icons-round">
                            lock
                          </span>                          <input type="password" name="password" placeholder="Contraseña" className="form-control" id="yourPassword" autoComplete="new-password"
                            onChange={(e) => setPassword(e.target.value)} title="Debe contener al menos 8 caracteres, una mayúscula, una minúscula y un número." 
                            required />
                          <div className="invalid-feedback">Por favor, ingresa la contraseña!</div>
                          {passwordOk === false && <p className="text-danger">Debe cumplir con al menos un dígito o la longitud mínima de 8 caracteres.</p>}
                        </div>
                        {!passwordsMatch && <p className="text-danger">Las contraseñas no coinciden.</p>}
                        <div class="form-row" style={{ flexWrap: "nowrap", alignItems: "center" }}>
                          <span className={` material-icons-round ${!passwordsMatch ? "text-danger" : ""}`}>
                            lock_outline
                          </span> 
                        <input type="password" name="password2"
                          className={`form-control ${!passwordsMatch ? "text-danger" : ""}`} placeholder="Repetir contraseña" id="yourPassword2" required autoComplete="new-password"
                          onChange={(e) => setPassword2(e.target.value)} title="Debe contener al menos 8 caracteres, una mayúscula, una minúscula y un número." />

                      </div>
                    </div>

                    <div className="form-right">
                      <h2>Tu Local</h2>
                      <div className="form-row" style={{ flexWrap: "nowrap", alignItems: "center" }}>
                        <i class="bi bi-diamond-half text-light"></i>

                        <select name="tipo" value={formData.title} onChange={handleChange} required>

                          <option value="">Tipo de Local - Elige una opción</option>
                          <option value="Cafetería">Cafetería</option>
                          <option value="Restaurante">Restaurante</option>
                          <option value="Bar">Bar - Cervecería</option>
                          <option value="Pizzería">Pizzería - Empanadas</option>
                          
                          <option value="panadería">Confitería - Panadería</option>
                          <option value="Heladería">Heladería</option>
                          <option value="Food-truck">Food Truck</option>
                          <option value="Dietética">Dietética</option>
                          <option value="carnicería">Carnicería</option>
                          <option value="Vinoteca">Vinoteca</option>
                          

                          <option value="Kiosko">Kiosko</option>
                        </select>
                      </div>
                      <div class="form-row" style={{ flexWrap: "nowrap", alignItems: "center" }}>
                        <i className="bi-shop-window text-light"></i>
                        <input type="text" name="local" placeholder="Nombre del Local" required onChange={handleChange} />

                      </div>
                      <div class="form-row" style={{ flexWrap: "nowrap", alignItems: "center" }}>
                        <i class="bi bi-geo-alt text-light"></i>
                        <input type="text" autoComplete="off" id="ubic" name="direccion" onChange={handleChangeDire} onBlur={handleChangeUbi} value={address} placeholder="Dirección" required />






                        {address && (
                          <button
                            type="button"
                            className="btn"
                          >
                            <i class="bi bi-search text-light"></i>
                          </button>
                        )}

                      </div>

                      {error && <p style={{ color: "warning", fontSize: "14px" }}>{error}</p>}
                      <ul className="direccion">
                        {results.map((result) => {
                          const formattedAddress = `${result.address.road || ""
                            } ${result.address.house_number || ""}, ${result.address.suburb || ""
                            }, ${result.address.state ===
                              "Ciudad Autónoma de Buenos Aires"
                              ? "CABA"
                              : result.address.state
                            }`;
                          const isValidAddress = formattedAddress.trim() !== "";
                          return (
                            isValidAddress && (
                              <li
                                key={result.place_id}
                                onClick={() =>
                                  handleSelectAddress(
                                    `${result.address.road} ${result.address.house_number
                                    }, ${result.address.suburb}. ${result.address.state ===
                                      "Ciudad Autónoma de Buenos Aires"
                                      ? "CABA"
                                      : result.address.state
                                    }`,
                                    //, ${result.address.state_district}
                                    result.lat,
                                    result.lon
                                  )
                                }
                              >
                                {formattedAddress}
                              </li>
                            )
                          );
                        })}
                      </ul>

                      <div className="form-group">
                        <div class="form-row" style={{ flexWrap: "nowrap", alignItems: "center" }}>
                          <i class="bi bi-whatsapp text-white"></i>
                          <input type="text" name="whatsapp" placeholder="WhatsApp" required onChange={handleChange} />

                        </div>
                        <div class="form-row" style={{ flexWrap: "nowrap", alignItems: "center" }}>
                          <i class="bi bi-telephone text-white"></i>
                          <input type="text" name="telefono" placeholder="Teléfono" onChange={handleChange} />

                        </div>

                      </div>

                    </div>
                </div>

                <div className="form-check col-12  m-2" style={{ flexWrap: "nowrap", alignItems: "center" }}>
                  <input className="form-check-input w-10 bg-light p-2" style={{ width: "18px" }} name="terminos" type="checkbox" value="" id="acceptTerms" required />
                  <label className="form-check-label" for="acceptTerms">Acepto los <a >terminos y condiciones</a></label>
                  <div className="invalid-feedback">Debes agregar antes de continuar.</div>


                </div>
                <button className="btn btn-primary  mb-4" type="submit">Registro Gratis</button>
              </form>



            </div>
          </div>


        </div>
    </div>
        </div >

      </section >


    </div >

  )
}