
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import AppContext from "../../context/AppContext";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";


export default function PromocionAlta() {

  const { active, api, setTitulo } = useContext(AppContext);
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const [usuario] = useCookies(['usuarioadmi']);
  const [isLoading, setIsLoading] = useState(false);

        useEffect(() => {
              setTitulo('Gestión Promociones')
            
          }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true)
    const formData = new FormData(event.target);
    const user = {
      //nombre: formData.get('titulo'),
      activo: formData.get('activo'),
      comentario: formData.get('comentario'),
      //inicio: formData.get('inicio'),
      fin: formData.get('fin'),
      capacidad: formData.get('capacidad'),
      codigo: formData.get('codigo'),
      porcentaje: formData.get('porcentaje'),
      monto: formData.get('monto'),
      minimo: formData.get('tope'),
      uso: 0,
      idusuario: usuario.usuarioadmi.id,
    };
    try {
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const response = await fetch(`${api}/api/promocion/alta`, requestOptions);
      const data = await response.status;
      console.log(data);

      // Si la respuesta indica que el registro fue exitoso,
      // actualizamos la variable de estado para mostrar el mensaje.
      if (data === 200) {
        setRegistroExitoso(true);
        event.target.reset();
        setIsLoading(true)
        notification.open({
          message: 'Notificación',
          description: 'Registro exitoso',
          icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
        });
        setIsLoading(false)
      } else {
        setIsLoading(false)
        notification.open({
          message: "Ocurrio un error 😢",
          description: `${data.response}`,
          icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
        });

      }
    } catch (error) {
      setIsLoading(false)

      notification.open({
        message: "Ocurrio un error 😢",
        description: `${error.response?.data || error.message}`,
        icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
      });
    }
  };

  return (
    <main id="main" className={active === 'active' ? 'main active' : 'main'}>

      {/* <div className="pagetitle">
  <nav>
    <ol className="breadcrumb">
      <h2>Gestión Mesa</h2>
    </ol>
  </nav>
</div>   {registroExitoso && (
        <div className="alert alert-success" role="alert">
          ¡Registro exitoso!
        </div>
      )}*/}


      <section className="section">
        <div className="row">
          <div className="col-lg-8">

            <div className="card">
              <div className="card-body">
                <h5 className="">Alta Promoción</h5>


                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <label for="inputText" className="col-sm-2 col-form-label">Activo</label>
                    <div className="col-sm-5 form-check form-switch">
                      <input className="form-check-input" type="checkbox" name="activo" id="gridCheck2" value="true" checked="true" />
                    </div>
                  </div>
                  {/*   <div className="row mb-3">
                    <label for="inputText" className="col-sm-2 col-form-label">Titulo</label>
                    <div className="col-sm-5">
                      <input type="text" className="form-control" name="titulo" required />
                    </div>
                  </div> */}
                  <div className="row mb-3">
                    <label for="inputText" className="col-sm-2 col-form-label">Código Cupón</label>
                    <div className="col-sm-5">
                      <input type="text" className="form-control" name="codigo" required />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputText" className="col-sm-3 col-form-label">Porcentaje Descuento</label>
                    <div className="col-sm-4 input-group">
                      <span class="input-group-text">%</span>
                      <input type="number" className="form-control" name="porcentaje" required />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputText" className="col-sm-3 col-form-label">Tope Descuento</label>
                    <div className="col-sm-4 input-group">
                      <span class="input-group-text">$</span>
                      <input type="number" className="form-control" name="tope" required />
                    </div>
                  </div>
                  <div className="row mb-3">

                    <label for="inputText" className="col-sm-3 col-form-label">Consumo Mínimo</label>
                    <div className="col-sm-4 input-group">
                      <span class="input-group-text">$</span>
                      <input type="number" className="form-control" name="monto" required />
                    </div>
                  </div>
                  {/*   <div className="row mb-3">
                    <label for="inputPassword" className="col-sm-2 col-form-label">Inicio</label>
                    <div className="col-sm-5 ">
                      <input type="date" className="form-control" name="inicio" required />
                    </div>
                  </div> */}
                  <div className="row mb-3">
                    <label for="inputPassword" className="col-sm-2 col-form-label">Vencimiento</label>
                    <div className="col-sm-5">
                      <input type="date" className="form-control" name="fin" required />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputNumber" className="col-sm-2 col-form-label">Capacidad</label>
                    <div className="col-sm-5">
                      <input type="number" className="form-control" name="capacidad" required />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputEmail" className="col-sm-2 col-form-label">Comentario</label>
                    <div className="col-sm-5">
                      <textarea type="text" className="form-control" name="comentario"></textarea>
                    </div>
                  </div>
                  {/* <div className="row mb-3">
              <label for="inputDate" className="col-sm-2 col-form-label">Plan</label>
              

              <div className="col-sm-5">
                <select className="form-select" aria-label="Default select example">
                  <option selected="">Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>*/}
                  <div className="text-center center">
                    {isLoading ? <button className="btn btn-primary rounded-pill" ><div className="loading d-block isLoading"></div></button> :
                      <button type="submit" className="btn btn-primary rounded-pill">
                        Registrar
                      </button>}
                  </div>

                </form>

              </div>
            </div>

          </div>

        </div>
      </section>

    </main>

  )

}