import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { useCookies } from "react-cookie";
import { notification } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import Register from "./register";
import Sucrip from "./sucrip";
import { use } from "react";


export default function Login() {
  const [registroExitoso, setRegistroExitoso] = useState();
  const [data, setData] = useState(false);
  const { handleLoginSuccess, api } = useContext(AppContext);
  const [usuario, setUsuario] = useCookies(['usuarioadmi']);
  const navigate = useNavigate();
  const location = useLocation();

  const clickMenu = () => {
    localStorage.removeItem('Admin')
    window.location.replace('/')
  };

  const handleRegistration = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const user = {

      email: formData.get('email'),
      password: formData.get('password')
    }

    try {
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const response = await fetch(`${api}/api/admin/login`, requestOptions);


      if (response.ok) {
        const dataPromise = await response.json();
        setUsuario('usuarioadmi', dataPromise.usuario, { path: '/', maxAge: 840 * 60 });
        const data = await response.status;

        setData(data);

        //handleLoginSuccess(true)
        const expirationTime = new Date().getTime() + 12 * 60 * 60 * 1000; // 4 horas en milisegundos
        localStorage.setItem('registroExitoso', JSON.stringify({ value: true, expirationTime }));

        setRegistroExitoso(true);
        navigate('/Admin', { replace: true });

      } else {
        handleLoginSuccess(false)
        setRegistroExitoso(false);
      }
    } catch (error) {
      notification.open({
        message: "Ocurrio un error 😢",
        description: `${error.response?.data || error.message}`,
        icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
      });

    }

  };
  const [activeButton, setActiveButton] = useState("button1");

  const handleClick = (buttonId) => {
    setActiveButton(buttonId);
    if (buttonId === "button1") {
      navigate('Admin/Ingreso');
    }else{
      navigate('Admin/Registro');
    }
  };

  useEffect(() => {

    if (location.pathname.includes("Registro")) {
      setActiveButton("button2");
    }
  }, [activeButton]);

  return (

    <main>
      <div className="login-background"></div>
      {registroExitoso === false ?
        <div className="alert alert-danger" role="alert">
          ¡El registro falló! Verifique su email o contraseña.
        </div> : <></>
      }
      <div className="container">

        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center mt-0">
          <div className="container">
            <div className="row justify-content-center">

              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                <div className="d-flex justify-content-center py-4">
                  <spam onClick={() => clickMenu()} className="logo d-flex align-items-center w-auto">
                    <img src="https://app.clickmenu.com.ar:8083/img/cmlogowhite.png" alt="" />
                    <span className=" d-lg-block">ALIADOS</span>
                  </spam>


                </div>


                <center className="grid">
                  <div>
                    <div className="btn-group">
                      <button
                        type="button"
                        className={`btn ${activeButton === "button1"
                          ? "btn-primary"
                          : "btn-secondary sombra"
                          }`}
                        onClick={() => handleClick("button1")}
                      >
                        <i class="bi bi-key"></i> Ingreso
                      </button>
                      <button
                        type="button"
                        className={`btn ${activeButton === "button2"
                          ? "btn-primary"
                          : "btn-secondary sombra"
                          }`}
                        onClick={() => handleClick("button2")}
                      >
                        <i class="bi bi-stars"></i> Registro
                      </button>
                    </div>
                  </div>

                </center>

                {activeButton === "button1" ?
                  <div className="card mb-3 mt-3">

                    <div className="card-body">

                      <div className="pt-4 pb-2">
                        <h5 className=" text-center pb-0 fs-4">Ingresa a tu Cuenta</h5>
                        <p className="text-center small">Ingresar tu email y contraseña para iniciar</p>
                      </div>

                      <form className="row g-3 needs-validation" onSubmit={handleRegistration}>

                        <div className="col-12">
                          <label className="form-label">Email</label>


                          <input type="text" name="email" className="form-control" id="youremail" required autoComplete="current-email" />
                          <div className="invalid-feedback">¡Por favor, ingresar tu email!</div>

                        </div>

                        <div className="col-12">
                          <label className="form-label">Contraseña</label>
                          <input type="password" name="password" className="form-control" id="yourPassword" required autoComplete="current-password" />
                          <div className="invalid-feedback">¡Por favor, ingresar tu contraseña!</div>
                        </div>

                        {/* <div className="col-12">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe"/>
                            <label className="form-check-label">Remember me</label>
                          </div>
                        </div> */}
                        <div className="col-12">
                          <button className="btn btn-primary w-100" type="submit">Ingresar</button>
                        </div>
                        {/* <div className="col-12">
                        <p className="small mb-0">¿Necesitas confirmar una cuenta nueva?  <Link to="/Admin/registro">Ingresa aquí</Link></p>
                      </div> */}
                      </form>
                      <button className="btn-secondary btn small mt-4 mb-2 w-90" onClick={() => clickMenu()}>Página Principal</button>

                    </div>


                  </div> : <Sucrip></Sucrip>}


              </div>
            </div>
          </div>

        </section>

      </div>
    </main>
  )
}