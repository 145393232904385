import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
//import { render } from "react-dom";
import React, { useEffect, useState } from "react";
import useInitialState from "../hooks/useInitialState";
import "../vendor/swiper/swiper-bundle.min.css";
import AppContext from "../context/AppContext";
import MenuApp from "./MenuApp";
import AdminApp from "./AdminApp";
import AOS from "aos";

export default function App() {
  const initialState = useInitialState();
  const [admin, setAdmin] = useState(null);
  
  useEffect(() => {
    AOS.init();
  
    // Verificar si localStorage está disponible antes de usarlo
    try {
      const storedAdmin = localStorage.getItem('Admin');
      setAdmin(storedAdmin);
    } catch (error) {
      console.warn("No se pudo acceder a localStorage:", error);
    }
  }, []);

  return (
    <AppContext.Provider value={initialState}>
      <BrowserRouter>

        {admin ?
          <AdminApp /> :
          <MenuApp className='bodyMenu'/>}

      </BrowserRouter >
       </AppContext.Provider >)
}
